export const TYPES = {
  IApiService: Symbol("IApiService"),
  IStaticService: Symbol("IStaticService"),
  IConfigService: Symbol("IConfigService"),
  IHelperService: Symbol("IHelperService"),
  ILanguageService: Symbol("ILanguageService"),
  IEventEmitterService: Symbol("IEventEmitterService"),
  ITokenService: Symbol("ITokenService"),
  IAuthService: Symbol("IAuthService"),
  IHotlineService: Symbol("IHotlineService"),
  IAssessmentService: Symbol("IAssessmentService"),
  IRegistrationService: Symbol("IRegistrationService"),
  IWorkshopService: Symbol("IWorkshopService"),
  IArticleService: Symbol("IArticleService"),
  IProfileService: Symbol("IProfileService"),
  IAppointmentService: Symbol("IAppointmentService"),
  IDateService: Symbol("IDateService"),
  IUserService: Symbol("IUserService"),
  IBackofficeAppointmentService: Symbol("IBackofficeAppointmentService"),
  IAccountManagementService: Symbol("IAccountManagementService"),
  IFileService: Symbol("IFileService"),
  IFrontPageService: Symbol("IFrontPageService"),
  IFormService: Symbol("IFormService"),
  IBackofficeHotlineService: Symbol("IBackofficeHotlineService"),
  IBlacklistService: Symbol("IBlacklistService"),
  IBackofficeZoomService: Symbol("IBackofficeZoomService"),
  IThemeService: Symbol("IThemeService"),
  IPrescriptionService: Symbol("IPrescriptionService"),
  IMedicineService: Symbol("IMedicineService"),
  ILineContactService: Symbol("ILineContactService"),
}
