import type { Container } from "inversify"
import type { IConfigService } from "services/configService"
import { TYPES } from "services/types"

export class LogoViewModel {
  private decorateStyle: string

  public get branch(): string {
    if (this.decorateStyle === "production") {
      return ""
    }
    return this.decorateStyle.toUpperCase()
  }

  public get color(): string {
    switch (this.decorateStyle) {
      case "localhost":
        return "#3d5afe"
      case "canary":
        return "#ff9100"
      case "dev":
        return "#ff4081"
      case "staging":
        return "#4caf50"
      default:
        return "grey"
    }
  }

  constructor(container: Container) {
    const configService = container.get<IConfigService>(TYPES.IConfigService)
    this.decorateStyle = configService.decorateStyle
  }
}
