import type { Container } from "inversify"
import { action, observable } from "mobx"
import type { IAuthService } from "services/authService"
import { TYPES } from "services/types"
import type { IUserService } from "services/userService"

export class UserAgreementModalViewModel {
  private authService: IAuthService
  private userService: IUserService

  @observable
  public agreement: string = ""

  @observable
  public visible: boolean = false

  @observable
  public error: string = ""

  constructor(container: Container) {
    this.authService = container.get<IAuthService>(TYPES.IAuthService)
    this.userService = container.get<IUserService>(TYPES.IUserService)
  }

  @action.bound
  public async init() {
    try {
      const isCustomer = await this.userService.isCustomer
      if (isCustomer) {
        const user = await this.userService.user
        if (user.customer && !user.customer?.accept_agreement) {
          this.agreement = await this.authService.getAgreement()
          this.visible = true
        }
      }
    } catch {}
  }

  @action.bound
  public async acceptAgreement() {
    try {
      await this.userService.acceptAgreement(true)
      this.visible = false
    } catch (error) {
      this.error = "cannotAcceptAgreement"
    }
  }
}
