import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import { action, observable, computed } from "mobx"
import type { RefObject, UIEvent } from "react"
import type { IAuthService } from "services/authService"
import type { Container } from "inversify"
import { TYPES } from "services/types"

export class AgreementModalViewModel {
  private authService: IAuthService
  private onAccept?: () => void
  private onDecline?: () => void

  public cardElement?: RefObject<HTMLDivElement>

  @observable
  public title: string

  @observable
  public agreement: string

  @observable
  public force: boolean = true

  @observable
  public visible: boolean = false

  @observable
  public error?: string

  @observable
  public accepted: {
    test: boolean,
    appointment: boolean,
    workshop: boolean,
    journey: boolean,
    all: boolean
  } = {
    test: false,
    appointment: false,
    workshop: false,
    journey: false,
    all: false
  }

  @observable
  public acceptAvailable: boolean = false

  constructor(
    container: Container,
    title: string,
    agreement: string,
    force?: boolean,
    visible?: boolean,
    error?: string,
    onAccept?: () => void,
    onDecline?: () => void,
  ) {
    this.authService = container.get<IAuthService>(TYPES.IAuthService)
    this.title = title
    this.agreement = agreement
    this.force = force ?? this.force
    this.visible = visible ?? this.visible
    this.error = error
    this.onAccept = onAccept
    this.onDecline = onDecline
  }

  @computed
  get disableAccept() {
    return this.accepted.test && this.accepted.appointment && this.accepted.workshop && this.accepted.journey;
  }

  @computed
  get acceptable() {
    return this.accepted.all && this.accepted.test && this.accepted.appointment && this.accepted.workshop && this.accepted.journey;
  }

  @action.bound
  public onAcceptCheckboxChange(e: CheckboxChangeEvent) {
    this.accepted.all = e.target.checked
  }

  @action.bound
  public onOptionChange(
    e: CheckboxChangeEvent,
    s: "test" | "appointment" | "workshop" | "journey" | "all"
  ) {
    this.accepted[s] = e.target.value
  }

  @action.bound
  public async onContinueClicked() {
    if (this.onAccept) {
      this.onAccept()
    }
  }

  public getAgreementPDF() {
    return this.authService.getPrivacyPolicyURL()
  }
}
