import React, { useEffect, useState } from "react"
import { FrontPageViewModel } from "./FrontPageViewModel"
//libs
import { useObserver } from "mobx-react"
import { useTranslator } from "i18n/useTranslator"
import { useNavigation, Link } from "react-navi"
import { useContainer } from "services/containerProvider"

//components
import { MainLayout } from "components/layout/MainLayout2WithHotline/MainLayout"
import { Button } from "components/base/Button/Button"
import { Space } from "components/base/Space/Space"
import { Row } from "components/base/Row/Row"
import { Col } from "components/base/Col/Col"
import { ServiceCard } from "components/partial/ServiceCard/ServiceCard"
import { Modal } from "components/base/Modal/Modal"
import Carousel from "components/base/Carousel/Carousel"
import { Alert } from "components/base/Alert/Alert"

import { image } from "assests/images/frontpage/frontPageImage"
import styles from "./FrontPage.module.less"

interface IProps {
  carouselImages: any
}

export const FrontPage = (props: IProps): JSX.Element => {
  const t = useTranslator()
  const [viewModel] = useState(
    new FrontPageViewModel(useContainer(), useNavigation(), props.carouselImages)
  )
  const [scrollYPosition, setScrollYPosition] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", event => {
      setScrollYPosition(window.pageYOffset)
    })
  }, [])
  const scrollToSection = (id: any) => {
    var elmnt = document.getElementById(id)
    if (elmnt) {
      elmnt.scrollIntoView()
    }
  }

  return useObserver(() => (
    <MainLayout shouldHotlineBeAffix={true}>
      <div className={styles.frontPageWrapper}>
        {viewModel.showCarousel && (
          <div className={styles.bannerWrapper}>
            <Carousel>
              {viewModel.carouselImages.map((carousel: any) => {
                return (
                  <a
                    key={carousel.id}
                    href={carousel.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className={styles.bannerImg} src={carousel.cover_image} alt="" />
                  </a>
                )
              })}
            </Carousel>
          </div>
        )}
        <div className={styles.header}>
          <div className={styles.headerTitle}>{t("frontPage.mentalService")}</div>
          <div className={styles.headerDetail}>{t("frontPage.mentalServiceDetail")}</div>
        </div>

        {scrollYPosition === 0 && (
          <div className={styles.navigationWrapper}>
            <img
              src={image.arrowDownIcon}
              className={styles.IconDown}
              alt=""
              onClick={() => scrollToSection("service")}
            />
          </div>
        )}

        <div className={styles.mainTopBg}>
          <img src={image.mainTopBg} alt="" />
        </div>

        <div id="service" className={styles.serviceWrapper}>
          <Space>
            <img src={image.dashIcon} alt="" />
            <div className={styles.sectionTitle}>{t("frontPage.mentalServiceActive")}</div>
          </Space>
          <div className={styles.serviceContent}>
            <Row gutter={8} className={styles.serviceRow}>
              <Col xs={12} sm={12} md={8} className={styles.serviceCol}>
                <ServiceCard
                  name={t("frontPage.mentalRecord")}
                  description={t("frontPage.mentalRecordDetail")}
                  image={image.folderIcon}
                  onClick={() => viewModel.goServicePage("profile")}
                />
              </Col>

              <Col xs={12} sm={12} md={8} className={styles.serviceCol}>
                <ServiceCard
                  name={t("frontPage.article")}
                  description={t("frontPage.articleDetail")}
                  image={image.chartIcon}
                  onClick={() => viewModel.goServicePage("article")}
                />
              </Col>

              <Col xs={12} sm={12} md={8} className={styles.serviceCol}>
                <ServiceCard
                  name={t("frontPage.assessment")}
                  description={t("frontPage.assessmentDetail")}
                  image={image.emotionIcon}
                  onClick={() => viewModel.goServicePage("assessment")}
                />
              </Col>

              <Col xs={12} sm={12} md={8} className={styles.serviceCol}>
                <ServiceCard
                  name={t("frontPage.workshop")}
                  description={t("frontPage.workshopDetail")}
                  image={image.emotionIcon}
                  onClick={() => viewModel.goServicePage("workshop")}
                />
              </Col>

              <Col xs={12} sm={12} md={8} className={styles.serviceCol}>
                <ServiceCard
                  name={t("frontPage.consultDoctor")}
                  description={t("frontPage.consultDoctorDetail")}
                  image={image.bubbleIcon}
                  onClick={() => viewModel.goServicePage("appointment")}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className={styles.mainBottomBg}>
          <img src={image.mainBottomBg} alt="" />
        </div>

        <div id="mentalhealthjourney" className={styles.mentalHealthWrapper}>
          <Space>
            <img src={image.dashIcon} alt="" />
            <div
              className={styles.sectionTitle}
              style={{ cursor: "pointer" }}
              onClick={() => viewModel.goServicePage("mindtracking")}>
              {t("frontPage.mindJourney")}
            </div>
          </Space>
          <Row gutter={{ xs: 0, sm: 0, md: 70 }} className={styles.mentalRow}>
            <Col xs={24} sm={24} md={12}>
              <img
                src={image.mentalHealthJourneyImage}
                className={styles.mentalHealthImage}
                alt=""
              />
            </Col>
            <Col xs={24} sm={24} md={12} className={styles.mentalContent}>
              <div className={styles.frontPageDetail}>{t("frontPage.mindJourneyDetail")}</div>
              <Button
                className={styles.goJourney}
                onClick={() => viewModel.goServicePage("mindtracking")}>
                {t("frontPage.goMindJourney")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  ))
}
