import { injectable, inject } from "inversify"
import { TYPES } from "./types"
import type { IApiService } from "./apiService"
import type { IForm, IFormApiResponse } from "domains/interfaces/form"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type {
  IRegisterRequestBody,
  IUsernameAvailableResponse,
  IEmailAvailableResponse
} from "domains/interfaces/registration"

export interface IRegistrationService {
  getAdditionalForm(): Promise<IForm>
  register(registrationBody: IRegisterRequestBody): Promise<void>
  isUsernameAvailable(username: string): Promise<boolean>
  isEmailAvailable(email: string): Promise<boolean>
}

@injectable()
export class RegistrationService implements IRegistrationService {
  private apiService: IApiService

  constructor(@inject(TYPES.IApiService) apiService: IApiService) {
    this.apiService = apiService
  }

  public async getAdditionalForm(): Promise<IForm> {
    const response = await this.apiService.get<IFormApiResponse>("auth/register/form")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async register(registrationBody: IRegisterRequestBody): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      "auth/register",
      registrationBody
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async isUsernameAvailable(username: string): Promise<boolean> {
    const response = await this.apiService.post<IUsernameAvailableResponse>(
      "auth/username-available",
      { username }
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return false
    }
    return response.data.data?.available ?? false
  }

  public async isEmailAvailable(email: string): Promise<boolean> {
    const response = await this.apiService.post<IEmailAvailableResponse>(
      "auth/email-available",
      { email }
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return false
    }
    return response.data.data?.available ?? false
  }
}
