import type { Container } from "inversify"
import { computed, observable } from "mobx"
import type { IConfigService } from "services/configService"
import { TYPES } from "services/types"

export class NavbarMobileMenuViewModel {
  private configService: IConfigService

  @observable
  public isOpen: boolean = false

  @observable
  public selectedKey?: string

  @computed
  public get isAppointmentEnabled() {
    return this.configService.appointmentEnable
  }

  constructor(container: Container, isOpen?: boolean, selectedKey?: string) {
    this.configService = container.get<IConfigService>(TYPES.IConfigService)
    this.isOpen = isOpen ?? this.isOpen
    this.selectedKey = selectedKey
  }
}
