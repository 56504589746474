import React, { ReactNode, useState, useEffect } from "react"
import { Layout } from "antd"
import { NavBar } from "components/partial/NavBar/NavBar"
import { useLoadingRoute } from "react-navi"
import { PageLoading } from "components/partial/PageLoading/PageLoading"
import { MainLayoutViewModel } from "./MainLayoutViewModel"

import styles from "./MainLayout.module.less"
import { useObserver } from "mobx-react"
import { UserAgreementModal } from "components/partial/AgreementModal/UserAgreementModal/UserAgreementModal"
import { FooterContent } from "components/partial/FooterContentWithHotline/FooterContent"
import { FooterAgreement } from "components/partial/FooterAgreement/FooterAgreement"

const { Header, Content, Footer } = Layout

export interface IMainLayoutProps {
  children: ReactNode
  selectedKey?: string
  isLoading?: boolean
  className?: string
  shouldHotlineBeAffix?: boolean
  shouldShowFooter?: boolean
}

export const MainLayout = ({
  isLoading = false,
  shouldHotlineBeAffix = false,
  shouldShowFooter = true,
  ...props
}: IMainLayoutProps): JSX.Element => {
  const [viewModel] = useState(new MainLayoutViewModel())
  const loadingRoute = useLoadingRoute()

  useEffect(() => {
    viewModel.loading = isLoading
  }, [viewModel, isLoading])

  return useObserver(() => (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#ffffff" }}>
      <PageLoading isLoading={!!loadingRoute || viewModel.loading} />
      <FooterAgreement />
      <Header className={styles.header}>
        <NavBar selectedKey={props.selectedKey} />
      </Header>
      <UserAgreementModal />
      <Content className={`${styles.container} ${props.className}`}>{props.children}</Content>
      {shouldShowFooter && (
        <Footer style={{ background: "#66bb9e" }}>
          <FooterContent hotlineAttribute={{ isAffix: shouldHotlineBeAffix }} />
        </Footer>
      )}
    </Layout>
  ))
}
