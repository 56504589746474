import type { Container } from "inversify"
import type { IAuthService } from "services/authService"
import { TYPES } from "services/types"

export class NotFoundViewModel {
  private authService: IAuthService

  constructor(container: Container) {
    this.authService = container.get<IAuthService>(TYPES.IAuthService)
    this.init = this.init.bind(this)
    this.isAuth = this.isAuth.bind(this)
  }

  public init() {
    const pathName = window.location.pathname
    if (!this.authService.isLoggedIn && !this.authService.isAdminLoggedIn) {
      if (pathName.includes("/backoffice")) {
        console.log("passed here")
        window.location.href = `/backoffice/login?redirect=${encodeURIComponent(pathName)}`
      } else {
        window.location.href = `/login?redirect=${encodeURIComponent(pathName)}`
      }
    }
  }

  public isAuth() {
    return this.authService.isLoggedIn || this.authService.isAdminLoggedIn
  }
}
