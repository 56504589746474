import { injectable, inject } from "inversify"
import { observable } from "mobx"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type {
  IZoomCreateMeetingData,
  IZoomListMyMeetingsQuery,
  IZoomMeeting,
  IZoomMeetingPage,
  IZoomToken,
} from "domains/interfaces/backoffice/zoom"
import type { IApiService } from "./apiService"
import type { IConfigService } from "./configService"
import { TYPES } from "./types"

export interface IBackofficeZoomService {
  readonly baseOAuthUrl: string
  readonly hasLinkedZoomAccount: boolean
  readonly isEnabled: boolean
  readonly isInitializing: boolean
  linkZoomAccount(code: string): Promise<any>
  unlinkZoomAccount(zoomtokenId?: number): Promise<any>
  createMeeting(data: IZoomCreateMeetingData): Promise<IZoomMeeting>
  createMeetingOnlineAppointment(
    appointmentId: number,
    data: IZoomCreateMeetingData
  ): Promise<IZoomMeeting>
  listMyMeetings(query: IZoomListMyMeetingsQuery): Promise<IZoomMeetingPage>
  getInstantMeetingTemplate(): IZoomCreateMeetingData
}

@injectable()
export class BackofficeZoomService implements IBackofficeZoomService {
  private apiService: IApiService
  private configService: IConfigService
  private zoomToken: IZoomToken | null | undefined

  public get baseOAuthUrl(): string {
    const clientId = this.configService.zoomClientId
    const redirectUri = this.configService.zoomRedirectUri
    return `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`
  }

  public get hasLinkedZoomAccount(): boolean {
    return this.zoomToken ? true : false
  }

  public get isEnabled(): boolean {
    return this.configService.zoomClientId !== "" && this.configService.zoomRedirectUri !== ""
  }

  @observable
  public isInitializing: boolean = true

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.IConfigService) configService: IConfigService
  ) {
    this.apiService = apiService
    this.configService = configService
    this.getMyZoomToken().then(zoomToken => {
      this.zoomToken = zoomToken
      this.isInitializing = false
    })
  }

  public async linkZoomAccount(code: string): Promise<any> {
    const response = await this.apiService.get<IBaseResponse<{ zoomToken: IZoomToken }>>(
      `zoom/me/link/oauth`,
      { code },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    this.zoomToken = response.data.data?.zoomToken
    return response.data.data
  }

  public async unlinkZoomAccount(zoomTokenId?: number): Promise<any> {
    const response = await this.apiService.get<IBaseResponse<{}>>(
      `zoom/me/unlink`,
      { zoomTokenId: zoomTokenId ?? this.zoomToken!.id },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    this.zoomToken = null
    return response.data.data
  }

  public async createMeeting(meetingData: IZoomCreateMeetingData): Promise<IZoomMeeting> {
    const response = await this.apiService.post<IBaseResponse<{ meeting: IZoomMeeting }>>(
      `zoom/me/meetings`,
      meetingData,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data!.meeting
  }

  public async createMeetingOnlineAppointment(
    appointmentId: number,
    meetingData: IZoomCreateMeetingData
  ): Promise<IZoomMeeting> {
    const response = await this.apiService.post<IBaseResponse<{ meeting: IZoomMeeting }>>(
      `backoffice/counselor/appointment/${appointmentId}/startonline`,
      meetingData,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data!.meeting
  }

  public async listMyMeetings(query: IZoomListMyMeetingsQuery): Promise<IZoomMeetingPage> {
    const response = await this.apiService.get<IBaseResponse<IZoomMeetingPage>>(
      `zoom/me/meetings`,
      query,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data!
  }

  public getInstantMeetingTemplate(): IZoomCreateMeetingData {
    return {
      topic: "Instant meeting",
      type: 2,
      password: this.generateMeetingPassword(),
      agenda: "This meeting is a ZOOM instant meeting",
      settings: {
        hostVideo: true,
        participantVideo: true,
        waitingRoom: true,
      },
    }
  }

  private async getMyZoomToken(): Promise<IZoomToken | null> {
    const response = await this.apiService.get<IBaseResponse<{ zoomToken: IZoomToken | null }>>(
      `zoom/me/token`
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data!.zoomToken
  }

  private generateMeetingPassword(length: number = 10) {
    let mask = ""
    mask += "abcdefghijklmnopqrstuvwxyz"
    mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    mask += "0123456789"
    mask += "@-_*"
    let password = ""
    for (let i = 0; i < length; i++) {
      password += mask[Math.floor(Math.random() * mask.length)]
    }
    return password
  }
}
