import React from "react"
import { IMainLayoutProps, MainLayout } from "../MainLayout"
import { Card } from "antd"

import styles from "./MainLayoutWithCard.module.less"

export interface IMainLayoutWithCardProps extends IMainLayoutProps {}

export const MainLayoutWithCard = ({ children, ...props }: IMainLayoutProps): JSX.Element => {
  return (
    <MainLayout {...props}>
      <div className={styles.cardContainer}>
        <Card>{children}</Card>
      </div>
    </MainLayout>
  )
}
