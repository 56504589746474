import { useObserver } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Link } from "react-navi"
import { useTranslator } from "i18n/useTranslator"
import { useContainer } from "services/containerProvider"
import { Menu } from "components/base/Menu/Menu"
import { MenuItem } from "components/base/Menu/MenuItem/MenuItem"

import styles from "./NavbarMobileMenu.module.less"
import { NavbarMobileMenuViewModel } from "./NavbarMobileMenuViewModel"

export interface INavbarMobileMenuProps {
  isOpen?: boolean
  selectedKey?: string
}

export const NavbarMobileMenu = ({
  isOpen = false,
  selectedKey,
}: INavbarMobileMenuProps): JSX.Element => {
  const [viewModel] = useState(new NavbarMobileMenuViewModel(useContainer(), isOpen, selectedKey))
  const t = useTranslator()

  useEffect(() => {
    viewModel.isOpen = isOpen
  }, [viewModel, isOpen])

  useEffect(() => {
    viewModel.selectedKey = selectedKey
  }, [viewModel, selectedKey])

  return useObserver(() => (
    <div className={`${styles.container} ${viewModel.isOpen ? styles.open : ""}`}>
      {viewModel.isOpen && (
        <Menu theme="light" mode="vertical" selectedKeys={[viewModel.selectedKey ?? ""]}>
          <MenuItem key="profile">
            <Link href="/profile">Mind Journey</Link>
          </MenuItem>
          <MenuItem key="article">
            <Link href="/article">{t("navbar.article")}</Link>
          </MenuItem>
          <MenuItem key="assessment">
            <Link href="/assessment"> {t("navbar.assessment")}</Link>
          </MenuItem>
          <MenuItem key="workshop">
            <Link href="/workshop">{t("navbar.workshop")}</Link>
          </MenuItem>
          <MenuItem key="appointment">
            {viewModel.isAppointmentEnabled && (
              <Link href="/appointment"> {t("navbar.appointment")}</Link>
            )}
            {!viewModel.isAppointmentEnabled && (
              <a
                href="https://wellness.chula.ac.th/?q=th/user/client_login"
                target="_blank"
                rel="noreferrer">
                {t("navbar.appointment")}
              </a>
            )}
          </MenuItem>
        </Menu>
      )}
    </div>
  ))
}
