import React, { useState } from "react"
import { Form } from "components/base/Form/Form"
import { MainLayoutWithCard } from "components/layout/MainLayout/WithCard/MainLayoutWithCard"
import { RegisterViewModel } from "./RegisterViewModel"
import { useContainer } from "services/containerProvider"
import { FormItem } from "components/base/Form/FormItem/FormItem"
import { TextInput } from "components/base/TextInput/TextInput"
import { Divider } from "components/base/Divider/Divider"
import { Button } from "components/base/Button/Button"
import { Link, useCurrentRoute, useNavigation } from "react-navi"

import styles from "./Register.module.less"
import { useTranslator } from "i18n/useTranslator"
import { RadioGroup } from "components/base/RadioGroup/RadioGroup"
import { Radio } from "components/base/Radio/Radio"
import { Row } from "components/base/Row/Row"
import { Alert } from "components/base/Alert/Alert"
import { useObserver } from "mobx-react"

export const Register = (): JSX.Element => {
  const t = useTranslator()
  const [viewModel] = useState(new RegisterViewModel(useContainer(), useNavigation()))

  return useObserver(() => (
    <MainLayoutWithCard>
      <Form layout="vertical" onFinish={viewModel.onSubmitForm}>
        <div className={styles.textCenter}>
          <h1>{t("register.register")}</h1>
        </div>

        <FormItem
          label={t("register.email")}
          name="email"
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.email") }),
            },
            {
              type: "email",
              message: t("register.notEmail"),
            },
          ]}>
          <TextInput />
        </FormItem>
        <FormItem
          label={t("register.username")}
          name="username"
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.username") }),
            },
            {
              async validator(rule, value) {
                if (!value) {
                  return
                }
                if (!(await viewModel.isUsernameAvailable(value))) {
                  throw t("register.usernameNotAvailable")
                }
              },
            },
            {
              async validator(rule, value) {
                if (!value) {
                  return
                }
                if (!viewModel.validateUsernameAlphaNum(value)) {
                  throw t("register.usernameOnlyAlphaNum")
                }
              },
            },
          ]}>
          <TextInput />
        </FormItem>
        <FormItem
          label={t("register.password")}
          name="password"
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.password") }),
            },
          ]}>
          <TextInput type="password" />
        </FormItem>
        <FormItem
          label={t("register.confirmPassword")}
          name="confirmPassword"
          dependencies={["password"]}
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.confirmPassword") }),
            },
            ({ getFieldValue }) => ({
              async validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return
                }
                throw t("register.passwordNotMatch")
              },
            }),
          ]}>
          <TextInput type="password" />
        </FormItem>
        <Divider />
        <FormItem
          label={t("register.givenName")}
          name="firstname"
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.givenName") }),
            },
          ]}>
          <TextInput />
        </FormItem>
        <FormItem
          label={t("register.familyName")}
          name="lastname"
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.familyName") }),
            },
          ]}>
          <TextInput />
        </FormItem>
        <FormItem
          label={t("register.sex")}
          name="sex"
          required={false}
          rules={[
            {
              required: true,
              message: t("register.pleaseSelectWithName", { name: t("register.sex") }),
            },
          ]}>
          <RadioGroup>
            <Radio value="male">{t("register.male")}</Radio>
            <Radio value="female">{t("register.female")}</Radio>
            <Radio value="other">{t("register.other")}</Radio>
          </RadioGroup>
        </FormItem>
        {viewModel.errorMessage && (
          <FormItem>
            <Alert
              message={t(`register.${viewModel.errorMessage}`)}
              type="error"
              showIcon
              closable
            />
          </FormItem>
        )}
        <Row justify="center">
          <Button type="primary" htmlType="submit" className={styles.registerButton}>
            {t("register.register")}
          </Button>
        </Row>
        <div className={styles.textCenter}>
          {t("register.alreadyRegistered")}{" "}
          <Link href="/login" className={styles.textPrimaryColor}>
            {t("register.login")}
          </Link>
        </div>
      </Form>
    </MainLayoutWithCard>
  ))
}
