import { injectable, inject } from "inversify"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type {
  IAssessment,
  IAllAssessmentResponse,
  IAssessmentResponse,
  IAssessmentView,
  IAssessmentAnswerForm,
  IAssessmentSubmitResponse,
  IAssessmentUserResult,
  IAssessmentResultResponse,
  IAssessmentUserResultWithAnswer,
  IAssessmentConstraintAnswer,
  IAssessmentFeedbackContact,
  IAssessmentFreshmenBundleResponse,
  IAssessmentFreshmenBundle,
  IAssessmentFreshmenBundlePackResponse,
  IAssessmentFreshmenBundlePack,
  IAssessmentFreshmenBundleSubmitResponse,
  IAssessmentFreshmenBundleSubmit,
  IAssessmentFreshmenReportFacultyResponose,
  IAssessmentFreshmenReportFaculty,
} from "domains/interfaces/assessment"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type { IStaticService } from "./staticService"
import type { LanguageString } from "./languageService"
import type { IFormResponseAnswer } from "domains/interfaces/form"
import type { IOneTimeToken, IOneTimeTokenResponse } from "domains/interfaces/oneTimeToken"

export interface IAssessmentService {
  getAll(): Promise<IAssessment[]>
  getById(
    id: number,
    includes: { question: boolean; constraint: boolean },
    userId?: any
  ): Promise<IAssessment | null>
  getByNameEn(
    name: string,
    includes: { question: boolean; constraint: boolean }
  ): Promise<IAssessment | null>
  getByNameEnForUser(
    name: string,
    includes: { question: boolean; constraint: boolean },
    userId: number
  ): Promise<IAssessment | null>
  getMarkdownByAssessment(
    assessment: IAssessmentView,
    language?: LanguageString
  ): Promise<string | null>
  submitAssessment(
    assessment: IAssessment,
    answers: number[],
    constraints?: IAssessmentConstraintAnswer[],
    questionnaireAnswers?: IFormResponseAnswer[],
    additionalForm?: { key: string; answers: IFormResponseAnswer[] }[]
  ): Promise<IAssessmentUserResult>
  getResultById(id: number, userId?: any): Promise<IAssessmentUserResultWithAnswer>
  getLatestResultByAssessmentId(id: number): Promise<IAssessmentUserResult>
  getLatestResultForUserByAssessmentId(id: number, userId: number): Promise<any>
  submitFeedbackContact(
    data: IAssessmentFeedbackContact,
    result: IAssessmentUserResult
  ): Promise<void>
  checkIfResultSevere(result: IAssessmentUserResultWithAnswer): boolean
  isFreshmenBundleAvailable(): Promise<boolean>
  getLatestFreshmenBundleResult(): Promise<IAssessmentFreshmenBundle>
  getFreshmenBundlePack(): Promise<IAssessmentFreshmenBundlePack>
  submitFreshmenBundle(data: IAssessmentFreshmenBundleSubmit): Promise<IAssessmentFreshmenBundle>
  getFreshmenReport(): Promise<IAssessmentFreshmenReportFaculty[]>
  getFreshmenReportExcelToken(): Promise<IOneTimeToken>
  getFreshmenReportExcelLink(): Promise<string>
}

@injectable()
export class AssessmentService implements IAssessmentService {
  private apiService: IApiService
  private staticService: IStaticService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.IStaticService) staticService: IStaticService
  ) {
    this.apiService = apiService
    this.staticService = staticService
  }

  public async getAll(): Promise<IAssessment[]> {
    const response = await this.apiService.get<IAllAssessmentResponse>("assessment", {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return []
    }
    return response.data.data.assessments
  }

  public async getById(
    id: number,
    includes: { question: boolean; constraint: boolean },
    userId?: any
  ): Promise<IAssessment | null> {
    const includeParams = []
    if (includes.question) {
      includeParams.push("questions")
    }
    if (includes.constraint) {
      includeParams.push("constraints")
    }
    const includeParamString = includeParams.join(",")
    const response = await this.apiService.get<IAssessmentResponse>(
      `assessment/${id}`,
      { includes: includeParamString, user: userId },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    return response.data.data.assessment
  }

  public async getByNameEn(
    name: string,
    includes: { question: boolean; constraint: boolean }
  ): Promise<IAssessment | null> {
    const includeParams = []
    if (includes.question) {
      includeParams.push("questions")
    }
    if (includes.constraint) {
      includeParams.push("constraints")
    }
    const includeParamString = includeParams.join(",")
    const response = await this.apiService.get<IAssessmentResponse>(
      `assessment/en/${name}`,
      { includes: includeParamString },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    return response.data.data.assessment
  }

  public async getByNameEnForUser(
    name: string,
    includes: { question: boolean; constraint: boolean },
    userId: number
  ): Promise<IAssessment | null> {
    const includeParams = []
    if (includes.question) {
      includeParams.push("questions")
    }
    if (includes.constraint) {
      includeParams.push("constraints")
    }
    const includeParamString = includeParams.join(",")
    const response = await this.apiService.get<IAssessmentResponse>(
      `backoffice/assessment/en/${userId}/${name}`,
      { includes: includeParamString },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    return response.data.data.assessment
  }

  public async getMarkdownByAssessment(
    assessment: IAssessmentView,
    language?: LanguageString
  ): Promise<string | null> {
    let resource = assessment.markdown
    if (language) {
      resource = assessment[`markdown_${language}` as "markdown_en" | "markdown_th"]
    }
    return await this.staticService.getResource<string>(resource)
  }

  public async submitAssessment(
    assessment: IAssessment,
    answers: number[],
    constraints?: IAssessmentConstraintAnswer[],
    questionnaireAnswers?: IFormResponseAnswer[],
    additionalForm?: { key: string; answers: IFormResponseAnswer[] }[]
  ): Promise<IAssessmentUserResult> {
    const questions = assessment.questions
    if (!questions) {
      throw new Error("Unexpected error")
    }
    const answerForm: IAssessmentAnswerForm = {
      questions: questions.map((question, index) => {
        return {
          id: question.id,
          choiceId: question.choices[answers[index]].id,
        }
      }),
      constraints,
      questionnaireAnswers,
      additionalForm,
    }
    const response = await this.apiService.post<IAssessmentSubmitResponse>(
      `assessment/${assessment.id}/submit`,
      answerForm,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  public async getResultById(id: number, userId?: any): Promise<IAssessmentUserResultWithAnswer> {
    const response = await this.apiService.get<IAssessmentResultResponse>(
      `assessment/result/${id}`,
      { user: userId },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  public async getLatestResultByAssessmentId(id: number): Promise<IAssessmentUserResult> {
    const response = await this.apiService.get<IAssessmentResultResponse>(
      `assessment/${id}/result/latest`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  public async getLatestResultForUserByAssessmentId(
    id: number,
    userId: number
  ): Promise<IAssessmentUserResult> {
    const response = await this.apiService.get<IAssessmentResultResponse>(
      `assessment/${id}/result/${userId}/user/latest`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  public async submitFeedbackContact(
    data: IAssessmentFeedbackContact,
    result: IAssessmentUserResult
  ): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      "assessment/feedback-contact",
      {
        ...data,
        resultId: result.id,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public checkIfResultSevere(result: IAssessmentUserResultWithAnswer): boolean {
    const keywords = ["นัดหมาย", "จิตแพทย์", "รุนแรง", "นักจิตวิทยา"]
    for (const criteriaResult of result.criteria_results) {
      if (criteriaResult.advice_th) {
        for (const keyword of keywords) {
          if (criteriaResult.advice_th.includes(keyword)) {
            return true
          }
        }
      }
    }
    return false
  }

  public async isFreshmenBundleAvailable(): Promise<boolean> {
    const response = await this.apiService.get<IBaseResponse<{ available: boolean }>>(
      "assessment/freshmen-survey/available"
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data?.available ?? false
  }

  public async getLatestFreshmenBundleResult(): Promise<IAssessmentFreshmenBundle> {
    const response = await this.apiService.get<IAssessmentFreshmenBundleResponse>(
      "assessment/freshmen-survey/result/latest"
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  public async getFreshmenBundlePack(): Promise<IAssessmentFreshmenBundlePack> {
    const response = await this.apiService.get<IAssessmentFreshmenBundlePackResponse>(
      "assessment/freshmen-survey"
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.bundle
  }

  public async submitFreshmenBundle(
    data: IAssessmentFreshmenBundleSubmit
  ): Promise<IAssessmentFreshmenBundle> {
    const response = await this.apiService.post<IAssessmentFreshmenBundleSubmitResponse>(
      "assessment/freshmen-survey",
      data,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.result
  }

  public async getFreshmenReport(): Promise<IAssessmentFreshmenReportFaculty[]> {
    const response = await this.apiService.get<IAssessmentFreshmenReportFacultyResponose>(
      "backoffice/assessment/freshmen-survey/report",
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      return []
    }
    return response.data.data.report
  }

  public async getFreshmenReportExcelToken(): Promise<IOneTimeToken> {
    const response = await this.apiService.get<IOneTimeTokenResponse>(
      "backoffice/assessment/freshmen-survey/report.xlsx/token",
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.oneTimeToken
  }

  public async getFreshmenReportExcelLink(): Promise<string> {
    const token = await this.getFreshmenReportExcelToken()
    const url = this.apiService.getApiUrl("backoffice/assessment/freshmen-survey/report.xlsx")
    return `${url}?one-time-token=${token.token}`
  }
}
