import type { Container } from "inversify"
import type { RefObject, UIEvent } from "react"
import { observable, action } from "mobx"
import { TYPES } from "services/types"
import type { IAuthService } from "services/authService"
import type { IUserService } from "services/userService"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"

export class FooterAgreementViewModel {
  private authService: IAuthService
  private userService: IUserService

  public cardElement?: RefObject<HTMLDivElement>

  @observable
  public agreement: string = ""

  @observable
  public visible: boolean = true

  @observable
  public error: string = ""

  @observable
  public accepted: boolean = false

  @observable
  public acceptAvailable: boolean = false

  @observable
  public isModalCookieSettingVisible: boolean = false

  @observable 
  public cookieValues: any = {
    analyze: true,
    marketing: true,
  }

  constructor(container: Container) {
    this.authService = container.get<IAuthService>(TYPES.IAuthService)

    this.userService = container.get<IUserService>(TYPES.IUserService)
  }

  @action.bound
  public async init() {
    try {
      const isLogin = await this.authService.isLoggedIn
      if (!isLogin || sessionStorage.getItem("agreement_unsign") !== "agreed") {
        this.agreement = await this.authService.getAgreement()
        this.visible = true
      } 
    } catch (ex) {}
  }

  @action.bound
  public async acceptAgreement() {
    try {
      await this.userService.acceptAgreement(true)
      this.visible = false
    } catch (error) {
      this.error = "cannotAcceptAgreement"
    }
  }

  @action.bound
  public onCardScroll(e: UIEvent<HTMLDivElement>) {
    if (this.cardElement?.current) {
      if (
        Math.ceil(this.cardElement.current.scrollTop) >=
        this.cardElement.current.scrollHeight - this.cardElement.current.clientHeight
      ) {
        this.acceptAvailable = true
      }
    }
  }
  @action.bound
  public async onContinueClicked() {
    this.visible = false
    sessionStorage.setItem("agreement_unsign", "agreed")
  }

  @action.bound
  public onAcceptCheckboxChange(e: CheckboxChangeEvent) {
    this.accepted = e.target.checked
  }

  @action.bound
  public setModalVisible(bool: boolean)
  {
    this.visible = bool
  }

  @action.bound
  public setCookieSettingModalVisible(bool: boolean)
  {
    this.isModalCookieSettingVisible = bool
  }

  @action.bound
  public onConfirmCookie(acceptAll: boolean)
  {
    if(acceptAll)
    {
      this.cookieValues.analyze = true;
      this.cookieValues.marketing = true;
    }
    
    sessionStorage.setItem("agreement_unsign", "agreed")
    this.isModalCookieSettingVisible = false;
    this.visible = false;
  }

  @action.bound
  public onCookieSwitch(e: boolean, s: string)
  {
    this.cookieValues[s] = e;
  }

  public getAgreementPDF() {
    return this.authService.getPrivacyPolicyURL();
  }

  public getCookiePolicyPDF() {
    return this.authService.getCookiePolicyURL()
  }

  public openCookieSetting() {
    return this.isModalCookieSettingVisible = true;
  }
}
