import DashIcon from "./dash_icon.svg"
import BubbleIcon from "./bubble_icon.svg"
import FolderIcon from "./folder_icon.svg"
import EmotionIcon from "./emotion_icon.svg"
import MentalHealthJourneyImage from "./mentalHealthJourney.svg"
import ChartIcon from "./chart_icon.svg"
import HeaderBg from "./header_bg.svg"
import MainTopBg from "./main_top_bg.png"
import MainBottomBg from "./main_bottom_bg.png"
import ArrowDownIcon from "./arrow_down.svg"
//tem
import BannerImage from "./banner_image.svg"

export const image = {
  dashIcon: DashIcon,
  bubbleIcon: BubbleIcon,
  folderIcon: FolderIcon,
  emotionIcon: EmotionIcon,
  chartIcon: ChartIcon,
  mentalHealthJourneyImage: MentalHealthJourneyImage,
  headerBg: HeaderBg,
  mainTopBg: MainTopBg,
  mainBottomBg: MainBottomBg,
  bannerImage: BannerImage,
  arrowDownIcon: ArrowDownIcon,
}
