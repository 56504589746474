import React, { Suspense } from "react"
import HelmetProvider from "react-navi-helmet-async"
import { NotFoundBoundary, Router, View } from "react-navi"
import { ContainerProvider } from "./services/containerProvider"
import { I18nProvider } from "./i18n/I18nProvider"
import { routes } from "./routes"
import { PageLoading } from "./components/partial/PageLoading/PageLoading"
import { NotFound } from "./pages/Error/NotFound"

export const App = (): JSX.Element => {
  return (
      <ContainerProvider>
        <HelmetProvider>
          <Router routes={routes}>
            <Suspense fallback={<PageLoading isLoading={true} />}>
              <I18nProvider>
                <NotFoundBoundary render={NotFound}>
                  <View />
                </NotFoundBoundary>
              </I18nProvider>
            </Suspense>
          </Router>
        </HelmetProvider>
      </ContainerProvider>
  )
}
