import type { Container } from "inversify"
import { action, observable, computed } from "mobx"
import type { Navigation } from "navi"
//tem
import { image } from "assests/images/frontpage/frontPageImage"
import type { IConfigService } from "services/configService"
import { TYPES } from "services/types"

export class FrontPageViewModel {
  private configService: IConfigService

  private navigation: Navigation

  @observable
  public carouselImages: Array<any> = []

  @computed
  get showCarousel() {
    return this.carouselImages && this.carouselImages.length > 0 ? true : false
  }

  constructor(container: Container, navigation: Navigation<any>, carouselImages: any) {
    this.configService = container.get<IConfigService>(TYPES.IConfigService)
    this.navigation = navigation
    this.carouselImages = carouselImages
  }

  public goServicePage(service: string) {
    if (service === "appointment") {
      if (this.configService.appointmentEnable) {
        this.navigation.navigate(`/appointment`)
      } else {
        window.open("https://wellness.chula.ac.th/?q=th/user/client_login", "_blank")
      }
    } else if (service === "profile") {
      this.navigation.navigate(`/profile`)
    } else if (service === "assessment") {
      this.navigation.navigate(`/assessment`)
    } else if (service === "article") {
      this.navigation.navigate(`/article`)
    } else if (service === "workshop") {
      this.navigation.navigate(`/workshop`)
    } else if (service === "mindtracking") {
      this.navigation.navigate(`/profile/mentalhealthjourney`)
    }
  }

  // public goBannerServicePage(service: string, id: number) {
  //   if (service === "article") {
  //     this.navigation.navigate(`/article/${id}`)
  //   }
  // }
}
