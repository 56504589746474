import React, { ReactNode, useEffect, useState } from "react"
import { I18nProviderViewModel } from "./I18nProviderViewModel"
import { useTranslation } from "react-i18next"
import { useContainer } from "../services/containerProvider"

interface I18nProviderProps {
  children: ReactNode
}

export const I18nProvider = (props: I18nProviderProps): JSX.Element => {
  const { i18n } = useTranslation()
  const [viewModel] = useState(new I18nProviderViewModel(useContainer(), i18n))

  useEffect(() => {
    return () => {
      viewModel.dispose()
    }
  }, [viewModel])

  return <>{props.children}</>
}
