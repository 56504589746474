import React, { useState } from "react"
import { Affix } from "components/base/Affix/Affix"
import { Col } from "components/base/Col/Col"
import { Row } from "components/base/Row/Row"
import Phone from "assets/images/footer/phone.svg"
import Facebook from "assets/images/footer/facebook.svg"
import Hotline from "assets/images/hotline/hotline_icon.svg"
import { FooterContentViewModel } from "./FooterContentViewModel"
import { useContainer } from "services/containerProvider"

import styles from "./FooterContent.module.less"
import { useTranslator } from "i18n/useTranslator"

export interface IFooterContentProps {
  hotlineAttribute?: {
    isAffix: boolean
  }
}

export const FooterContent = ({
  hotlineAttribute = { isAffix: false },
  ...props
 }: IFooterContentProps): JSX.Element => {
  const t = useTranslator()
  const [viewModel] = useState(new FooterContentViewModel(useContainer()))
  const hotlineContent = (
    <a
      className={styles.footerLink}
      // target="_blank"
      href="https://bit.ly/LineMindCafe">
      {/* href="/hotline/chat-room/request"> */}
      {/* change redirect url for hotline */}
      <img src={Hotline} />
    </a>
  )

  return (
    <Row className={styles.row} align="middle" justify="center">
      <Col sm={24} md={12}>
        <div className={styles.name}>{t("footer.organization")}</div>
        {t("footer.address")} <br />
        <a
          className={styles.footerLink}
          target="_blank"
          href={viewModel.getAgreementPDF()}
          rel="noreferrer">
          นโยบายคุ้มครองข้อมูลส่วนบุคคล (ไฟล์)
        </a>
      </Col>
      <Col sm={24} md={6} className={styles.col}>
        <Row align="middle" justify="center" gutter={[4, 4]}>
          <Col>
            <img src={Phone} />
          </Col>
          <Col>
            {/* <a className={styles.footerLink} href="tel:022180540">
              02 218 0540
            </a>{" "}*/}
            {/* {t("footer.or")} <br /> */}
            <a className={styles.footerLink} href="tel:0850422626">
              08 5042 2626
            </a>
          </Col>
        </Row>
      </Col>
      <Col sm={24} md={4} className={styles.col}>
        <a
          className={styles.footerLink}
          target="_blank"
          href="https://www.facebook.com/chulastudentwellness"
          rel="noreferrer">
          <img src={Facebook} /> Chula Student Wellness
        </a>
      </Col>
      <Col sm={24} md={2} className={styles.col}>
        {hotlineAttribute.isAffix ? (
          <Affix offsetBottom={24} style={{ height: '100px', width: '102px' }}>{hotlineContent}</Affix>
        ) : (
          hotlineContent
        )}
      </Col>
    </Row>
  )
}
