import { injectable, inject } from "inversify"
import type { IConfigService } from "./configService"
import { TYPES } from "./types"
import axios from "axios"

export interface IStaticService {
  getResource<T>(path: string): Promise<T>
  getAssetUrl(path: string): string
}

@injectable()
export class StaticService implements IStaticService {
  private configService: IConfigService

  constructor(@inject(TYPES.IConfigService) configService: IConfigService) {
    this.configService = configService
  }

  public getAssetUrl(path: string): string {
    const url = new URL(path, this.configService.baseAssetUrl)
    return url.href
  }

  public async getResource<T>(path: string): Promise<T> {
    const response = await axios.get<T>(this.getAssetUrl(path))
    return response.data
  }
}
