import { injectable, inject } from "inversify"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type { IApiService } from "./apiService"
import type { IForm, IFormResponse, IFormResponseAnswer } from "domains/interfaces/form"
import { TYPES } from "./types"
import type {
  IBackofficeHotlineListResponse,
  IBackofficeHotlinePage,
  IQueue,
  IQueuePage,
} from "domains/interfaces/backoffice/hotline"
import type { IHotlineChatRoom } from "domains/interfaces/hotline"
import type {
  IBackofficeHotlineChatRoom,
  IChatRoomCasenoteFormResponse,
} from "domains/interfaces/backoffice/hotline"

export interface IBackofficeHotlineService {
  getChatRoom(chatRoomId: number): Promise<IBackofficeHotlineChatRoom | null>
  getChatRoomCasenoteForm(): Promise<{ form: IForm }>
  joinChatRoom(chatRoomId: number): Promise<IHotlineChatRoom>
  list(filter?: any): Promise<IBackofficeHotlinePage>
  submitChatRoomCasenote(
    chatRoomId: number,
    answer: { answers: IFormResponseAnswer[] }
  ): Promise<IFormResponse>
  getCases(myCase: boolean, filter?: any): Promise<any>
  editAssignedUsername(lineUserId: string, newAssignedUsername: string): Promise<void>
  deleteCaseNote(id: number): Promise<void>
}

@injectable()
export class BackofficeHotlineService implements IBackofficeHotlineService {
  private apiService: IApiService

  constructor(@inject(TYPES.IApiService) apiService: IApiService) {
    this.apiService = apiService
  }

  public async getChatRoom(chatRoomId: number): Promise<IBackofficeHotlineChatRoom | null> {
    const response = await this.apiService.get<
      IBaseResponse<{
        chat_room: IBackofficeHotlineChatRoom
      }>
    >(`backoffice/hotline/chat-rooms/${chatRoomId}`)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      return null
    }
    return response.data.data.chat_room
  }

  public async getChatRoomCasenoteForm(): Promise<{ form: IForm }> {
    const response = await this.apiService.get<IChatRoomCasenoteFormResponse>(
      `backoffice/hotline/chat-rooms/casenote/form`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    let formatData: any = response.data.data
    return formatData!
  }

  public async joinChatRoom(chatRoomId: number): Promise<IHotlineChatRoom> {
    const response = await this.apiService.get<IBaseResponse<{ chat_room: IHotlineChatRoom }>>(
      `hotline/chat-rooms/${chatRoomId}/join`
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.chat_room
  }

  public async list(filter: any = { page: 1, size: 10 }): Promise<IBackofficeHotlinePage> {
    const response = await this.apiService.get<IBackofficeHotlineListResponse>(
      "backoffice/hotline/chat-rooms",
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async submitChatRoomCasenote(
    chatRoomId: number,
    answer: { answers: IFormResponseAnswer[] }
  ): Promise<IFormResponse> {
    const response = await this.apiService.post<IBaseResponse<{ casenote: IFormResponse }>>(
      `backoffice/hotline/chat-rooms/${chatRoomId}/casenote`,
      answer,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.casenote
  }

  public async getCases(myCase: boolean, filter: any = { page: 1, size: 10 }): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/hotline/list?sortBy=created_at&sortOrder=desc&myCase=${myCase ? 1 : 0}`,
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async editAssignedUsername(
    newAssignedUsername: string,
    lineUserId: string
  ): Promise<void> {
    const response = await this.apiService.post<void>(
      `backoffice/hotline/line/custom-displayname`,
      {
        custom_display_name: `${newAssignedUsername}`,
        line_user_id: `${lineUserId}`,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async deleteCaseNote(id: number): Promise<void> {
    const response = await this.apiService.delete<void>(`backoffice/hotline/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }
}
