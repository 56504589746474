import React, { ReactNode } from "react"
import { Menu as AntdMenu } from "antd"
import type { MenuItemProps } from "antd/lib/menu/MenuItem"

export const MenuItem = ({
  children,
  ...props
}: MenuItemProps & { children: ReactNode }): JSX.Element => {
  return <AntdMenu.Item {...props}>{children}</AntdMenu.Item>
}
