import React from "react"

import styles from "./PageLoading.module.less"
import { Spin } from "components/base/Spin/Spin"

interface IPageLoadingProps {
  isLoading?: boolean
}

export const PageLoading = ({ isLoading = false }: IPageLoadingProps): JSX.Element => {
  return (
    <>
      {isLoading && (
        <div className={styles.overlayBox}>
          <Spin />
        </div>
      )}
    </>
  )
}
