import React, { ReactNode, RefAttributes } from "react"
import { Button as AntdButton } from "antd"
import type { ButtonProps } from "antd/lib/button"

export const Button = ({
  children,
  ...props
}: ButtonProps & RefAttributes<HTMLElement>): JSX.Element => {
  return <AntdButton {...props}>{children}</AntdButton>
}
