import { action, observable } from "mobx"

export class HamburgerViewModel {
  private onClick?: () => void

  @observable
  public isOpen: boolean = false

  constructor(isOpen?: boolean, onClick?: () => void) {
    this.onClick = onClick
    this.isOpen = isOpen ?? this.isOpen
  }

  @action.bound
  public onContainerClicked() {
    if (this.onClick) {
      this.onClick()
    }
  }
}
