import { injectable } from "inversify"
import type { StorageType } from "domains/interfaces/token"

export interface ITokenService {
  readonly storage: Storage
  readonly accessToken: string | null
  saveAccessToken(accessToken: string, isRemembered?: boolean): void
  removeAccessToken(): void
}

@injectable()
export class TokenService implements ITokenService {
  private storageType: StorageType

  public get storage(): Storage {
    switch (this.storageType) {
      case "session":
        return sessionStorage
      case "local":
        return localStorage
      default :
        throw new Error()
    }
  }

  public get accessToken(): string | null {
    return this.storage.getItem("accessToken")
  }

  public constructor() {
    this.storageType = this.determineStorageType()
  }

  private determineStorageType(): StorageType {
    if ((sessionStorage.getItem("accessToken")?.length ?? 0) > 0) {
      return "session"
    }
    return "local"
  }

  private setStorageType(storageType: StorageType) {
    switch (storageType) {
      case "session":
        localStorage.clear()
        break
      case "local":
        sessionStorage.clear()
        break
    }
    this.storageType = storageType
  }

  public saveAccessToken(accessToken: string, isRemembered: boolean = false) {
    if (isRemembered) {
      this.setStorageType("local")
    } else {
      this.setStorageType("session")
    }
    this.storage.setItem("accessToken", accessToken)
  }

  public removeAccessToken() {
    this.storage.removeItem("accessToken")
    this.storage.removeItem("loginType")
  }
}
