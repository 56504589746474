import { useObserver } from "mobx-react"
import React, { useEffect, useState } from "react"

import styles from "./Hamburger.module.less"
import { HamburgerViewModel } from "./HamburgerViewModel"

export interface IHamburgerProps {
  isOpen?: boolean
  onClick?: () => void
}

export const Hamburger = ({ isOpen = false, onClick }: IHamburgerProps): JSX.Element => {
  const [viewModel] = useState(new HamburgerViewModel(isOpen, onClick))
  useEffect(() => {
    viewModel.isOpen = isOpen
  }, [viewModel, isOpen])

  return useObserver(() => (
    <div
      className={`${styles.container} ${viewModel.isOpen ? styles.open : ""}`}
      onClick={viewModel.onContainerClicked}>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </div>
  ))
}
