import { injectable, inject } from "inversify"
import type { ILanguageService } from "./languageService"
import { TYPES } from "./types"

export interface IHelperService {
  convertToView<T, U>(object: T, language?: "en" | "th"): U
  convertAnswerToForm(answer: Array<any>): Object
  convertAnswerToView(answer: Array<any>): Array<any>
}

@injectable()
export class HelperService implements IHelperService {
  private languageService: ILanguageService

  constructor(@inject(TYPES.ILanguageService) languageService: ILanguageService) {
    this.languageService = languageService
  }

  public convertToView<T, U>(object: T, language?: "en" | "th"): U {
    const newObject = { ...object } as any
    const targetLanguage = language ?? this.languageService.language
    for (const [key, value] of Object.entries(object)) {
      if (key.length >= 3 && key[key.length - 3] === "_") {
        const keyLanguage = key.substring(key.length - 2, key.length)
        if (keyLanguage === targetLanguage) {
          newObject[key.substring(0, key.length - 3)] = value
        }
      }
    }
    return newObject as U
  }

  public convertAnswerToForm(answers: Array<any>) {
    if (!answers) return {}
    let formAnswers: any = {}
    answers.forEach((answer: any) => {
      const { question } = answer
      const { question_type } = question
      if (question_type === "choice" || question_type === "selection") {
        if (answer.choice) {
          formAnswers[question.question_en] = answer.choice.id
        }
      } else {
        if (answer.textinput) {
          formAnswers[question.question_en] = answer.textinput
        }
      }
    })

    return formAnswers
  }

  public convertAnswerToView(answers: Array<any>) {
    if (!answers) return []
    const sortAnswers = answers.sort(function (a, b) {
      return a.question.id - b.question.id
    })
    return sortAnswers.map((answer: any) => {
      const { question } = answer
      const { question_type } = question
      let value
      if (question_type === "choice" || question_type === "selection") {
        if (this.languageService.language === "th") {
          value = answer.choice && answer.choice.description_th
        } else {
          value = answer.choice && answer.choice.description_en
        }
      } else {
        value = answer.textinput
      }

      const formatQuestions: any = this.convertToView(question)

      return {
        id: question.id,
        no: question.no,
        question: formatQuestions.question,
        form_section_id: question.form_section_id,
        value,
      }
    })
  }
}
