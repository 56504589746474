import { injectable } from "inversify"

export interface IConfigService {
  baseApiUrl: string
  baseAssetUrl: string

  loginNormalEnable: boolean
  loginChulaEnable: boolean

  decorateStyle: string
  appointmentEnable: boolean

  pusherAppKey: string
  pusherAppCluster: string
  echoAppAuthEndpoint: string

  zoomClientId: string
  zoomRedirectUri: string
}

@injectable()
export class ConfigService implements IConfigService {
  baseApiUrl = process.env.REACT_APP_BASE_API_URL ?? ""
  baseAssetUrl = process.env.REACT_APP_BASE_ASSET_URL ?? ""

  loginNormalEnable = (process.env.REACT_APP_LOGIN_NORMAL_ENABLE ?? "true") === "true"
  loginChulaEnable = (process.env.REACT_APP_LOGIN_CHULA_ENABLE ?? "true") === "true"

  decorateStyle = process.env.REACT_APP_DECORATE_STYPE ?? "production"
  appointmentEnable = (process.env.REACT_APP_APPOINTMENT_ENABLE ?? "true") === "true"

  pusherAppKey = process.env.REACT_APP_PUSHER_APP_KEY ?? ""
  pusherAppCluster = process.env.REACT_APP_PUSHER_APP_CLUSTER ?? ""
  echoAppAuthEndpoint = process.env.REACT_APP_ECHO_APP_AUTH_ENDPOINT ?? ""

  zoomClientId = process.env.REACT_APP_ZOOM_CLIENT_ID ?? ""
  zoomRedirectUri = process.env.REACT_APP_ZOOM_REDIRECT_URI ?? ""
}
