import React, { ReactNode } from "react"
import { Dropdown as DropdownAntd } from "antd"
import type { DropDownProps } from "antd/lib/dropdown"

export const Dropdown = ({
  children,
  ...props
}: DropDownProps & { children: ReactNode }): JSX.Element => {
  return <DropdownAntd {...props}>{children}</DropdownAntd>
}
