import { observable, action } from "mobx"
import { injectable } from "inversify"

export type LanguageString = "en" | "th"

export interface ILanguageService {
  language: LanguageString
  setLanguage(language: LanguageString): void
  switchLanguage(): void
}

@injectable()
export class LanguageService implements ILanguageService {
  @observable
  public language: LanguageString = "th"

  @action.bound
  public setLanguage(language: LanguageString) {
    this.language = language.substring(0, 2) as LanguageString
  }

  @action.bound
  public switchLanguage() {
    switch (this.language.substring(0, 2)) {
      case "en":
        this.language = "th"
        break
      case "th":
        this.language = "en"
        break
    }
  }
}
