import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type { PrescriptionDetail } from "domains/interfaces/backoffice/prescription"
import { inject, injectable } from "inversify"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"

export interface IPrescriptionService {
  createNewPrescription(appointment_id: string, prescription: PrescriptionDetail[]): Promise<void>
  editPrescription(id: string, prescription: PrescriptionDetail[]): Promise<void>
  getUserPrescriptionHistory(userId: string): Promise<void>
  getPrescriptionById(prescriptionId: number): Promise<void>
  getAllergyTextById(customer_id: number): Promise<string>
  saveAllergyTextById(customer_id: number, text: string): Promise<string>
}

@injectable()
export class PrescriptionService implements IPrescriptionService {
  private apiService: IApiService

  constructor(@inject(TYPES.IApiService) apiService: IApiService) {
    this.apiService = apiService
  }

  public async createNewPrescription(
    appointment_id: string,
    prescription: PrescriptionDetail[]
  ): Promise<void> {
    await this.apiService.post<any>("backoffice/prescription", {
      appointment_id,
      details: prescription,
    })
  }

  public async editPrescription(id: string, prescription: PrescriptionDetail[]): Promise<void> {
    await this.apiService.post<any>(`backoffice/prescription/${id}`, {
      details: prescription,
    })
  }

  public async getUserPrescriptionHistory(userId: string) {
    const res = await this.apiService.get<any>(`backoffice/prescription/history/${userId}`)

    if (res.data.data) {
      throw new Error("Error: Failed to get user prescription history")
    }

    return res.data.data
  }

  public async getPrescriptionById(prescriptionId: number) {
    const res = await this.apiService.get<any>(`backoffice/prescription/${prescriptionId}`)

    if (res.data.data) {
      throw new Error("Error: Failed to get prescription by id")
    }

    return res.data.data.prescription
  }

  public async getAllergyTextById(customer_id: number) {
    const res = await this.apiService.get<any>(`backoffice/counselor/allergy/${customer_id}`)

    if (!res.data.data) {
      throw new Error("Error: Failed to get allergy by customer_id")
    }

    return res.data.data.allergy
  }

  public async saveAllergyTextById(customer_id: number, text: string): Promise<string> {
    const response = await this.apiService.post<any>(
      `backoffice/counselor/allergy/${customer_id}`,
      {
        allergy: text,
      }
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    if (!response.data.data) {
      throw new Error("Error: Failed to save allergy by customer_id")
    }

    return response.data.data.allergy
  }
}
