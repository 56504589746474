import React, { Component } from "react"
import { Carousel } from "antd"
import styles from "./Carousel.module.less"
//icon
import ArrowLeftIcon from "assests/images/frontpage/arrow_left.svg"
import ArrowRightIcon from "assests/images/frontpage/arrow_right.svg"

export default class CarouselComponent extends Component {
  public carousel: any
  constructor(props: any) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.carousel = React.createRef()
  }
  next() {
    this.carousel.next()
  }
  previous() {
    this.carousel.prev()
  }

  render() {
    return (
      <div className={styles.carousel}>
        <Carousel ref={node => (this.carousel = node)} {...this.props}>
          {this.props.children}
        </Carousel>
        <img src={ArrowLeftIcon} className={styles.arrowLeft} onClick={this.previous} alt="" />
        <img src={ArrowRightIcon} className={styles.arrowRight} onClick={this.next} alt="" />
      </div>
    )
  }
}
