import { injectable, inject } from "inversify"
import "moment/locale/th"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type { IConfigService } from "./configService"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type { IFileService } from "./fileService"
import type { ILanguageService } from "./languageService"

export interface IFrontPageService {
  getCarouselImageList(): Promise<any>
  getAllCarouselImage(): Promise<any>
  deleteCarouselImage(id: number): Promise<void>
  getCarouselImageById(id: any): Promise<any>
  createCarouselImage(formData: any): Promise<void>
  editCarouselImage(formData: any, id: number): Promise<void>
}

@injectable()
export class frontPageService implements IFrontPageService {
  private apiService: IApiService
  private configService: IConfigService
  private fileService: IFileService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ILanguageService) languageService: ILanguageService,
    @inject(TYPES.IConfigService) configService: IConfigService,
    @inject(TYPES.IFileService) fileService: IFileService
  ) {
    this.apiService = apiService
    this.configService = configService
    this.fileService = fileService
  }

  public async getCarouselImageList(): Promise<any> {
    const response = await this.apiService.get<any>("carousels", {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { carousels } = response.data.data
    return carousels.map((carousel: any) => {
      return { ...carousel, cover_image: this.fileService.getFileUrl(carousel.cover_image) }
    })
  }

  public async getAllCarouselImage(): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/carousels", {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { carousels } = response.data.data
    return carousels.map((carousel: any) => {
      return { ...carousel, cover_image: this.fileService.getFileUrl(carousel.cover_image) }
    })
  }

  public async deleteCarouselImage(id: number): Promise<void> {
    const response = await this.apiService.delete<void>(`backoffice/carousels/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getCarouselImageById(id: number): Promise<any> {
    const response = await this.apiService.get<any>(`backoffice/carousels/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { carousel } = response.data.data
    return { ...carousel, cover_image: this.fileService.getFileUrl(carousel.cover_image) }
  }

  public async createCarouselImage(formData: any): Promise<void> {
    const response = await this.apiService.postFormData<any>(`backoffice/carousels`, formData, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async editCarouselImage(formData: any, id: number): Promise<void> {
    const response = await this.apiService.postFormData<any>(
      `backoffice/carousels/${id}`,
      formData,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }
}
