import { useObserver } from "mobx-react"
import React, { useEffect, useRef, useState } from "react"
import { Checkbox } from "components/base/Checkbox/Checkbox"
import { Col } from "components/base/Col/Col"
import { Modal } from "components/base/Modal/Modal"
import { Row } from "components/base/Row/Row"
import { AgreementModalViewModel } from "./AgreementModalViewModel"
import { Markdown } from "components/base/Markdown/Markdown"
import { Button } from "components/base/Button/Button"
import { useTranslator } from "i18n/useTranslator"

import styles from "./AgreementModal.module.less"
import { Alert } from "components/base/Alert/Alert"
import { RadioGroup } from "components/base/RadioGroup/RadioGroup"
import { Radio } from "components/base/Radio/Radio"
import container from "inversify.config"

export interface IAgreementModalProps {
  agreement: string
  title: string
  force?: boolean
  visible?: boolean
  error?: string
  onAccept?: () => void
}

export const AgreementModal = ({
  agreement,
  title,
  force = true,
  visible = false,
  error,
  onAccept,
}: IAgreementModalProps): JSX.Element => {
  const t = useTranslator()
  const cardElement = useRef<HTMLDivElement>(null)
  const [viewModel] = useState(
    new AgreementModalViewModel(container, title, agreement, force, visible, error, onAccept)
  )

  useEffect(() => {
    viewModel.cardElement = cardElement
  }, [])

  useEffect(() => {
    viewModel.visible = visible
  }, [viewModel, visible])

  useEffect(() => {
    viewModel.error = error
  }, [viewModel, error])

  return useObserver(() => (
    <Modal
      title={viewModel.title}
      visible={viewModel.visible}
      closable={false}
      footer={[
        <Button
          key="continue"
          type="primary"
          disabled={!viewModel.acceptable}
          onClick={viewModel.onContinueClicked}>
          {t("agreement.continue")}
        </Button>,
      ]}>
      <div ref={cardElement} className={styles.agreementCard}>

        <p>จุฬาลงกรณ์มหาวิทยาลัยให้ความสําคัญต่อความปลอดภัยของข้อมูลส่วนบุคคลของท่าน ดังนั้น เราจะดูแล
          ข้อมูลส่วนบุคคลของท่านอย่างเหมาะสมเพื่อปกป้องข้อมูลส่วนบุคคลของท่านให้ปลอดภัยตามมาตรฐานสูงสุด
          สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
          ทางฝ่ายส่งเสริมสุขภาวะนิสิต ขอความยินยอมจากท่านเพื่อการประมวลผลข้อมูลส่วนบุคคลเพื่อ
          วัตถุประสงค์ดังที่ระบุไว้ กรุณากด “ยินยอม” หากท่านตกลงให้เราประมวลผลข้อมูลส่วนบุคคลของท่านตาม
          วัตถุประสงค์ดังต่อไปนี้</p>
        <div className={styles.cookieTextSection}>
          <h4>1. การทำแบบทดสอบทางจิตวิทยา (Mind Test)</h4>
          <p>
            ท่านยินยอมให้ทางผ่ายฯ ประมวลผลข้อมูล ข้อมูลสุขภาพจิต ข้อมูลการประเมินผลแบบวัดทางจิตวิทยา เพื่อวัตถุประสงค์ในการช่วยเหลือและการรักษาทางด้านสุขภาพจิต เช่น การนำข้อมูลไปจัดเก็บเข้าระบบเพื่อวางแผนในกระบวนการการปรึกษาเชิงจิตวิทยา การประชุมเพื่อพัฒนาคุณภาพของการให้บริการ
          </p>
          <RadioGroup
            onChange={(e) => viewModel.onOptionChange(e, "test")}
            value={viewModel.accepted.test}>
            <Radio value={true}>{t("agreement.accept")}</Radio>
            <Radio value={false}>{t("agreement.notaccept")}</Radio>
          </RadioGroup>
        </div>
        <div className={styles.cookieTextSection}>
          <h4>2. การนัดหมายบริการปรึกษาเชิงจิตวิทยาโดยนักจิตวิทยา (Mind Appointment)</h4>
          <p>
            ท่านยินยอมให้ทางผ่าายฯ ประมวลผลข้อมูล ข้อมูลสุขภาพจิต เพื่อวัตถุประสงค์ในการช่วยเหลือและการรักษาทางด้านสุขภาพจิต เช่น การนำข้อมูลไปจัดเก็บเข้าระบบเพื่อติดต่อช่วยเหลือทางสุขภาพจิตฉุกเฉินและทำการช่วยเหลือ หรือติดต่อหน่วยงานฉุกเฉินในกรณีที่เกิดเหตุการณ์ทางสุขภาพจิตขึ้น
          </p>
          <RadioGroup
            onChange={(e) => viewModel.onOptionChange(e, "appointment")}
            value={viewModel.accepted.appointment}>
            <Radio value={true}>{t("agreement.accept")}</Radio>
            <Radio value={false}>{t("agreement.notaccept")}</Radio>
          </RadioGroup>
        </div>
        <div className={styles.cookieTextSection}>
          <h4>3. การสมัครเข้าร่วมกิจกรรม (Mind Workshop)</h4>
          <p>
            ท่านยินยอมให้ทางผ่าายฯ ประมวลผลข้อมูล เพื่อวัตถุประสงค์ในการให้บริการทางด้านสุขภาพจิตและเพื่อวัตถุประสงค์ในการพิจารณาแนวทางการดูแลนิสิต เช่น นิสิตที่มีปัญหาด้านการเรียน
          </p>
          <RadioGroup
            onChange={(e) => viewModel.onOptionChange(e, "workshop")}
            value={viewModel.accepted.workshop}>
            <Radio value={true}>{t("agreement.accept")}</Radio>
            <Radio value={false}>{t("agreement.notaccept")}</Radio>
          </RadioGroup>
        </div>
        <div className={styles.cookieTextSection}>
          <h4>4. การวิเคราะห์การใช้งานเพื่อการพัฒนาจิตใจ (Mid Journey)</h4>
          <p>
            ท่านยินยอมให้ทางฝ่ายฯ ประมวลผลข้อมูล เพื่อวัตถุประสงค์ในการช่วยเหลือ รักษาทางด้านสุขภาพจิต เข่น การนำข้อมูลไปจัดเก็บเข้าระบบเพื่อวางแผนในกระบวนการการปรึกษาเชิงจิตวิทยา และเพื่อวัตถุประสงค์ในการพิจารณาแนวทางการดูแลนิสิต เช่น นิสิตที่มีปัญหาด้านการเรียน
          </p>
          <RadioGroup
            onChange={(e) => viewModel.onOptionChange(e, "journey")}
            value={viewModel.accepted.journey}>
            <Radio value={true}>{t("agreement.accept")}</Radio>
            <Radio value={false}>{t("agreement.notaccept")}</Radio>
          </RadioGroup>
        </div>
        <div className={styles.cookieTextSection}>
          ความยินยอมนี้ เราจะดูแลข้อมูลส่วนบุคคลของท่านตามมาตรฐานสูงสุด
          โดยเราจะจัดเก็บข้อมูลส่วนบุคคลตลอดระยะเวลาที่จำเป็นในการดำเนินการตามวัตถุประสงค์
          และจะลบข้อมูลหลังจากหมดความจำเป็นนั้น ทั้งนี้
          ท่านสามารถดูรายละเอียดของนโยบายคุ้มครองข้อมูลส่วนบุคคลสำหรับการใช้บริการของฝ่ายส่งเสริมสุขภาวะนิสิต
          ดูรายละเอียดได้ที่ [<a
            className={styles.footerLink}
            target="_blank"
            href={viewModel.getAgreementPDF()}
            rel="noreferrer">
            นโยบายคุ้มครองข้อมูลส่วนบุคคล (ไฟล์)
          </a>]
        </div>
        <div className={styles.cookieTextSection}>
          เรายินดีที่จะแจ้งให้ท่านทราบว่า ท่านสามารถเพิกถอนความยินยอมนี้ได้ทุกเมื่อ
          เว้นแต่จะมีฐานการประมวลผลตามกฎหมายอื่น นอกจากนี้
          ในกรณีที่มีการเปลี่ยนแปลงหรือเพิ่มเติมวัตถุประสงค์ใด ๆ
          เราจะแจ้งให้ท่านทราบล่วงหน้าตามช่องทางที่เหมาะสมต่อไป
          หากจำเป็นต้องได้รับความยินยอม เราจะขอความยินยอมจากท่านก่อนเสมอ
        </div>
      </div>
      <Row gutter={[8, 8]}>
        {viewModel.error && viewModel.error.length > 0 && (
          <Col span="24">
            <Alert message={t(`login.${viewModel.error}`)} type="error" showIcon closable />
          </Col>
        )}
        {!viewModel.disableAccept && (
          <Col span="24" className={styles.agreementRequiredText}>
            กรุณาอ่านและยอมรับเงื่อนไขการบริการ
          </Col>
        )}
        <Col span="24">
          <Checkbox
            disabled={!viewModel.disableAccept}
            onChange={viewModel.onAcceptCheckboxChange}>
            {t("agreement.iAgree")}
          </Checkbox>
        </Col>
      </Row>
    </Modal>
  ))
}
