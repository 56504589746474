import { injectable, inject } from "inversify"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type { ITokenService } from "./tokenService"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type { ILoginResponseData } from "domains/interfaces/authServiceResponse"
import { computed, observable, action } from "mobx"
import type { IStaticService } from "./staticService"
import type { IConfigService } from "./configService"

export interface ILoginConfigs {
  providerUserMapping?: {
    id: string,
    token: string,
  }
}

export interface IAuthService {
  readonly isLoggedIn: boolean
  readonly isAdminLoggedIn: boolean
  readonly isLoginNormalEnabled: boolean
  readonly isLoginChulaEnabled: boolean
  login(username: string, password: string, isRemembered?: boolean, configs?: ILoginConfigs): Promise<void>
  loginChula(username: string, password: string, isRemembered?: boolean, configs?: ILoginConfigs): Promise<void>
  loginBackoffice(username: string, password: string): Promise<void>
  backofficeLogout(): Promise<void>
  customerLogout(): Promise<void>
  getAgreement(): Promise<string>
  getDeniedAgreement(): Promise<string>
  getAgreementURL(): string
  getPrivacyPolicyURL(): string
  getCookiePolicyURL(): string
  resetPassword(currentPassword: string, newPassword: string): Promise<void>
  updateLoginType(type: "customer" | "backoffice"): void
}

@injectable()
export class AuthService implements IAuthService {
  private apiService: IApiService
  private tokenService: ITokenService
  private staticService: IStaticService
  private configService: IConfigService

  @observable
  private loginType?: "customer" | "backoffice"

  @computed
  public get isLoginNormalEnabled(): boolean {
    return this.configService.loginNormalEnable
  }

  @computed
  public get isLoginChulaEnabled(): boolean {
    return this.configService.loginChulaEnable
  }

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ITokenService) tokenService: ITokenService,
    @inject(TYPES.IStaticService) staticService: IStaticService,
    @inject(TYPES.IConfigService) configService: IConfigService
  ) {
    this.apiService = apiService
    this.tokenService = tokenService
    this.staticService = staticService
    this.configService = configService
    if (this.tokenService.accessToken) {
      this.loginType = this.tokenService.storage.getItem("loginType") as "customer" | "backoffice"
    }
  }

  @computed
  public get isLoggedIn(): boolean {
    return (
      (this.loginType === "customer" && (this.tokenService.accessToken ?? "") !== "") ||
      this.isAdminLoggedIn
    )
  }

  @computed
  public get isAdminLoggedIn(): boolean {
    return this.loginType === "backoffice" && (this.tokenService.accessToken ?? "") !== ""
  }

  @action.bound
  public updateLoginType(type: "customer" | "backoffice") {
    this.loginType = type
    this.tokenService.storage.setItem("loginType", type)
  }

  @action.bound
  public async login(
    username: string,
    password: string,
    isRemembered: boolean = false,
    configs: ILoginConfigs = {},
  ): Promise<void> {
    const response = await this.apiService.post<ILoginResponseData>("auth/login", {
      username,
      password,
      mapping_id: configs.providerUserMapping?.id,
      mapping_token: configs.providerUserMapping?.token,
    })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message ?? "")
    }
    this.tokenService.saveAccessToken(response.data.data?.accessToken ?? "", isRemembered)
    this.updateLoginType("customer")
  }

  @action.bound
  public async loginChula(
    username: string,
    password: string,
    isRemembered: boolean = false,
    configs: ILoginConfigs = {},
  ): Promise<void> {
    const response = await this.apiService.post<ILoginResponseData>("auth/login/chula", {
      username,
      password,
      mapping_id: configs.providerUserMapping?.id,
      mapping_token: configs.providerUserMapping?.token,
    })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message ?? "")
    }
    this.tokenService.saveAccessToken(response.data.data?.accessToken ?? "", isRemembered)
    this.updateLoginType("customer")
  }

  @action.bound
  public async loginBackoffice(username: string, password: string): Promise<void> {
    const response = await this.apiService.post<ILoginResponseData>("backoffice/auth/login", {
      username,
      password,
    })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message ?? "")
    }
    this.tokenService.saveAccessToken(response.data.data?.accessToken ?? "")
    this.updateLoginType("backoffice")
  }

  @action.bound
  public async backofficeLogout() {
    const response = await this.apiService.post<ILoginResponseData>("backoffice/auth/logout", {})
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message ?? "")
    }
    this.tokenService.removeAccessToken()
    this.loginType = undefined
  }

  @action.bound
  public async customerLogout() {
    const response = await this.apiService.post<ILoginResponseData>("auth/logout", {})
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message ?? "")
    }
    this.tokenService.removeAccessToken()
    this.loginType = undefined
  }

  public async getDeniedAgreement(): Promise<string> {
    try {
      return await this.staticService.getResource<string>("markdown/agreement/denied.md")
    } catch (ex) {
      console.log(ex)
      return ""
    }
  }

  public async getAgreement(): Promise<string> {
    try {
      // const agreement = await this.apiService.get<any>("users/agreement")
      // return agreement.data.data.agreement.agreement
      return await this.staticService.getResource<string>("markdown/agreement/denied.md")
    } catch {
      return ""
    }
  }

  public getAgreementURL(): string {
    try {
      return this.staticService.getAssetUrl("markdown/agreement/agreement.pdf")
    } catch {
      return ""
    }
  }

  public getPrivacyPolicyURL(): string {
    try {
      return this.staticService.getAssetUrl("markdown/agreement/MindSpace_Privacy_Policy.pdf")
    } catch {
      return ""
    }
  }

  public getCookiePolicyURL(): string {
    try {
      return this.staticService.getAssetUrl("markdown/agreement/MindSpace_Cookie_Policy.pdf")
    } catch {
      return ""
    }
  }

  public async resetPassword(currentPassword: string, newPassword: string): Promise<any> {
    const response = await this.apiService.post<any>("backoffice/auth/resetpassword", {
      currentPassword,
      newPassword,
    })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message ?? "")
    }
  }
}
