import { inject, injectable } from "inversify"
import moment, { Moment } from "moment"
import { TYPES } from "./types"
import type { ILanguageService } from "./languageService"
import type { AppointmentDateType } from "domains/types/appointment"

export interface IDateService {
  convertToJSDate(dateString: string): Moment
  getDaysWithInMonth(day: AppointmentDateType, withInMonth: number): Moment[]
  getStartEndDate(
    day: Moment,
    startHour: number,
    startMinute: number,
    endHour: number,
    endMinute: number
  ): Moment[]
  getWeek(skipWeek?: number): Moment[]
}

@injectable()
export class DateService implements IDateService {
  private languageService: ILanguageService

  constructor(@inject(TYPES.ILanguageService) languageService: ILanguageService) {
    this.languageService = languageService
  }

  public convertToJSDate(dateString: string) {
    moment.locale(this.languageService.language)
    return moment(dateString)
  }

  public getDaysWithInMonth(day: AppointmentDateType, withInMonth: number): Moment[] {
    let currentDay = moment().startOf("month").day(day)
    const today = moment().startOf("day")
    while (today.unix() >= currentDay.unix()) {
      currentDay = currentDay.clone().add(7, "d")
    }
    const days: Moment[] = []
    const endDay = today.clone().endOf("month").add(withInMonth, "months")
    while (currentDay <= endDay) {
      days.push(currentDay)
      currentDay = currentDay.clone().add(7, "d")
    }
    return days
  }

  public getStartEndDate(
    day: Moment,
    startHour: number,
    startMinute: number,
    endHour: number,
    endMinute: number
  ): Moment[] {
    day = day.startOf("day")
    const startDate = day.clone().add(startHour, "hours").add(startMinute, "minutes")
    const endDate = day.clone().add(endHour, "hours").add(endMinute, "minutes")
    return [startDate, endDate]
  }

  public getWeek(skipWeek = 0): Moment[] {
    /*
      // adding 2 days, to make sure that next active schedule is displayed.
      const day = moment().add(2, "day").startOf("week").startOf("day").add(skipWeek, "weeks")
    */
    const day = moment().add(1, "day").startOf("week").startOf("day").add(skipWeek, "weeks");
    const weeks: Moment[] = []
    for (let i = 0; i < 7; i++) {
      weeks.push(day.clone().add(i, "days"))
    }
    return weeks
  }
}
