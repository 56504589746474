import { inject, injectable } from "inversify"
import moment from "moment"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type { IForm } from "domains/interfaces/form"
import type {
  WorkshopDetail,
  Workshop,
  IWorkshopAnnounce,
} from "domains/interfaces/portfolio/workShop"
import type { IProfile } from "domains/interfaces/profile"
import type { IApiService } from "./apiService"
import type { IConfigService } from "./configService"
import type { IFileService } from "./fileService"
import type { IHelperService } from "./helperService"
import type { ILanguageService } from "./languageService"
import { TYPES } from "./types"

export interface IProfileService {
  getProfile(userId?: string): Promise<any>
  getMentalSupport(userId?: string): Promise<any>
  editProfile(profile: IProfile): Promise<IProfile>
  getAppointment(filter: any, userId?: string): Promise<any>
  getMindtraceList(month: number, filter: any, userId?: string): Promise<any>
  saveDailyMindtrace(form: any): Promise<void>
  getDailyMindtrace(userId?: string): Promise<void>
  getProfileForm(): Promise<any>
  getPortfolioOverview(month: number, filter: any, userId?: string): Promise<any>
  getUserHistory(filter: any, userId?: string): Promise<any>
  saveAvatarImage(formData: any): Promise<void>
  saveCoverImage(formData: any): Promise<void>
  getAllAvatar(): Promise<any>
  getAvatarGroup(): Promise<any>
  getAllCover(): Promise<any>
  joinSessionRoom(id: string): Promise<void>
  getSessionStatus(id: string): Promise<any>
  getHeaderProfile(userId?: string): Promise<any>
  getUserProfile(type: "form" | "detail", userId?: string): Promise<any>
  editUserProfile(formData: any): Promise<any>
  confirmAppointment(appointmentId: number): Promise<void>
  updateProblem(appointmentId: number, problem: string): Promise<any>
  cancelAppointment(appointmentId: number, form: any): Promise<any>
  getRegisterWorkshop(userId?: string): Promise<any>
  confirmWorkshop(workshopId: number): Promise<any>
  cancelWorkshop(workshopId: number, note: string): Promise<void>
  getClientCancelAppointmentForm(): Promise<IForm>
  getWorkshopDetail(workshopId: number, userId?: string): Promise<WorkshopDetail>
  getWorkshopAnnoucement(workshopId: number): Promise<IWorkshopAnnounce>
}

@injectable()
export class ProfileService implements IProfileService {
  private apiService: IApiService
  private configService: IConfigService
  private helperService: IHelperService
  private fileService: IFileService
  private languageService: ILanguageService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ILanguageService) languageService: ILanguageService,
    @inject(TYPES.IConfigService) configService: IConfigService,
    @inject(TYPES.IHelperService) helperService: IHelperService,
    @inject(TYPES.IFileService) fileService: IFileService
  ) {
    this.languageService = languageService
    this.apiService = apiService
    this.configService = configService
    this.helperService = helperService
    this.fileService = fileService
  }

  public mapServiceToText(service: string) {
    if (this.languageService.language === "th") {
      switch (service) {
        case "assessment":
          return "แบบทดสอบ"
        case "article":
          return "บทความสุขภาวะ"
        default:
      }
    } else {
      switch (service) {
        case "assessment":
          return "Mind test"
        case "article":
          return "Mind support"
        default:
      }
    }
  }

  public getUserDateText = (date: any, startTime: any, endTime: any) => {
    startTime = moment(date + " " + startTime).format("HH.mm")
    endTime = moment(date + " " + endTime).format("HH.mm")
    if (!date) return ""
    else {
      return `${moment(date).format("dddd, DD MMMM พ.ศ. ")} ${moment(date)
        .add(543, "year")
        .format("YYYY, ")} ${startTime} - ${endTime} น.`
    }
  }

  public getWorkshopDateText = (startDate: any, endDate: any) => {
    moment.locale("th")
    const mStartDate = startDate && moment(startDate).add(543, "year")
    const mEndDate = endDate && moment(endDate).add(543, "year")

    if (!startDate && !endDate) return ""
    if (!startDate) return mStartDate.format("DD/MM/YYYY HH:mm")
    if (!endDate) return mEndDate.format("DD/MM/YYYY HH:mm")
    if (
      new Date(startDate).getFullYear() === new Date(endDate).getFullYear() &&
      new Date(startDate).getMonth() === new Date(endDate).getMonth() &&
      new Date(startDate).getDate() === new Date(endDate).getDate()
    ) {
      // Get real date of weeks
      const dayText = mStartDate.subtract(543, "year").format("dddd")

      // Restore date in BE format
      mStartDate.add(543, "year")

      return `${dayText}, ${mStartDate.format("DD MMMM พ.ศ. YYYY,")} ${mStartDate.format(
        "HH:mm"
      )} - ${mEndDate.format("HH:mm")} น.`
    }
    return `${mStartDate.format("DD/MM/YYYY HH:mm")} - ${mEndDate.format("DD/MM/YYYY HH:mm")}`
  }

  public async getProfile(userId?: string): Promise<IProfile | null> {
    const response = await this.apiService.get<any>(`portfolio`, { user: userId }, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    const profile = response.data.data.data
    const { history, user } = profile
    return {
      ...profile,
      user: {
        ...user,
        avatar_image: this.fileService.getFileUrl(user.avatar_image),
        cover_image: this.fileService.getFileUrl(user.cover_image),
      },
      history: history.map((_history: any) => {
        return {
          ..._history,
          name: _history.name_th,
          title: this.mapServiceToText(_history.service),
        }
      }),
    }
  }

  public async getMentalSupport(userId?: string): Promise<any> {
    const response = await this.apiService.get<any>(
      `portfolio/mentalsupport`,
      { user: userId },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    const { history } = response.data.data

    return history.map((data: any) => {
      return {
        ...data,
        imageUrl: this.fileService.getFileUrl(data.imageUrl),
        topic: data.topic_th ? data.topic_th : data.topic,
      }
    })
  }

  public async editProfile(profile: any): Promise<any> {
    const response = await this.apiService.post<any>(`profile`, profile, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.profile
  }

  public async getAppointment(filter: any, userId?: string): Promise<any> {
    const response = await this.apiService.get<any>(`appointment`, { user: userId }, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return []
    }

    return response.data.data.appointments.map((appointment: any) => {
      const { statuses, counselor = {} } = appointment
      let status =
        statuses &&
        (statuses.length
          ? statuses[statuses.length - 1]
          : {
              status_key: "notfound",
              status_th: "ไม่ระบุสถานะ",
              status_en: "inconclusive",
            })
      status = this.helperService.convertToView(status)
      const appointment_date = appointment.date ? moment(appointment.date).format("DD/MM") : ""
      let appointment_name = this.languageService.language === "th" ? "การนัดหมาย" : "appointment"
      if (appointment.parent_appointment_id) {
        appointment_name =
          this.languageService.language === "th"
            ? `[นัดหมายต่อเนื่อง] ${appointment_name}`
            : `[follow-up] ${appointment_name}`
      }
      appointment_name = `${appointment_name} ${appointment_date}`

      return {
        ...appointment,
        id: appointment.id,
        status_key: status.status_key,
        status: status.status,
        name: appointment_name,
        appointment_date: appointment_date,
        appointment_date_text: this.getUserDateText(
          appointment.date,
          appointment.start_time,
          appointment.end_time
        ),
        appointment_type: appointment.type,
        type: "appointment",
        counselor: counselor
          ? { ...counselor, avatar_image: this.fileService.getFileUrl(counselor.avatar_image) }
          : {},
      }
    })
  }

  public async getMindtraceList(month: number, filter: any, userId?: string): Promise<any> {
    const startDate = moment(month, "MM").toISOString()
    const endDate = moment(month, "MM").endOf("month").toISOString()

    let apiFilter = { ...filter, startDate, endDate, user: userId }

    const response = await this.apiService.get<any>(`mindtraces`, apiFilter, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return {}
    }
    const { mindtraces } = response.data.data
    return mindtraces.data
  }

  public async getDailyMindtrace(userId?: string): Promise<any> {
    const response = await this.apiService.get<any>(`mindtraces/now`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    const { mindtraces } = response.data.data
    return mindtraces
  }

  public async saveDailyMindtrace(form: any): Promise<void> {
    const response = await this.apiService.post<any>(`mindtraces`, form, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getProfileForm(): Promise<any> {
    const response = await this.apiService.get<any>(`profile/listprofileform`)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async getPortfolioOverview(month: number, filter: any, userId?: string): Promise<any> {
    const startDate = moment(month, "MM").startOf("month").toISOString()
    const endDate = moment(month, "MM").endOf("month").toISOString()

    let apiFilter = { ...filter, fromDate: startDate, toDate: endDate, user: userId }

    const response = await this.apiService.get<any>(`portfolio/overview`, apiFilter, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return {}
    }

    const { overview } = response.data.data
    return overview
  }

  public async getUserHistory(filter: any, userId?: string): Promise<any> {
    const response = await this.apiService.get<any>(
      `portfolio/history?`,
      { ...filter, user: userId },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    const { history } = response.data.data
    return history.map((historyItem: any) => {
      let formatHistory: any = this.helperService.convertToView(historyItem)
      formatHistory.title = this.mapServiceToText(historyItem.service)
      return {
        ...formatHistory,
        name: formatHistory.name ? formatHistory.name : historyItem.name_th,
      }
    })
  }

  public async saveAvatarImage(formData: any): Promise<void> {
    const response = await this.apiService.postFormData<any>(`profile/setavatar`, formData, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async saveCoverImage(formData: any): Promise<void> {
    const response = await this.apiService.postFormData<any>(`profile/setcover`, formData, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getAllAvatar(): Promise<any> {
    const response = await this.apiService.get<any>(`profile/avatars`)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { avatars } = response.data.data
    return avatars.map((avatar: any) => {
      return { ...avatar, image_url: this.fileService.getFileUrl(avatar.image_url) }
    })
  }

  public async getAvatarGroup(): Promise<any> {
    const response = await this.apiService.get<any>(`profile/avatars/groups`, {
      includeAvatars: true,
    })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { avatarGroups } = response.data.data
    return avatarGroups.map((avatarGroup: any) => {
      const formatAvatars = avatarGroup.avatars.map((avatar: any) => {
        return { ...avatar, image_url: this.fileService.getFileUrl(avatar.image_url) }
      })
      return { ...avatarGroup, avatars: formatAvatars }
    })
  }

  public async getAllCover(): Promise<any> {
    const response = await this.apiService.get<any>(`profile/covers`)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { covers } = response.data.data
    return covers.map((cover: any) => {
      return { ...cover, image_url: this.fileService.getFileUrl(cover.image_url) }
    })
  }

  public async getHeaderProfile(userId?: string): Promise<any> {
    const response = await this.apiService.get<any>(`profile/mini`, { user: userId })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    const profile = response.data.data.form

    return {
      ...profile,
      avatar_image: this.fileService.getFileUrl(profile.avatar_image),
      cover_image: this.fileService.getFileUrl(profile.cover_image),
    }
  }

  public async getUserProfile(type: "form" | "detail", userId?: string): Promise<any> {
    const response = await this.apiService.get<any>(`profile`, { user: userId })
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { userInfo, userProfile, medicalHistory, currentForm } = response.data.data

    if (type === "form") {
      return {
        userInfo: userInfo,
        userProfile: this.helperService.convertAnswerToForm(userProfile),
        medicalHistory: this.helperService.convertAnswerToForm(medicalHistory),
        currentForm: currentForm,
      }
    } else {
      return {
        userInfo: userInfo,
        userProfile: this.helperService.convertAnswerToView(userProfile),
        medicalHistory: this.helperService.convertAnswerToView(medicalHistory),
        currentForm: currentForm,
      }
    }
  }

  public async editUserProfile(formData: any): Promise<void> {
    const response = await this.apiService.post<any>(`profile`, formData, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async confirmAppointment(appointmentId: number): Promise<void> {
    const response = await this.apiService.post<any>(
      `appointment/${appointmentId}/confirm`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async updateProblem(appointmentId: number, problem: string): Promise<void> {
    const response = await this.apiService.post<any>(
      `appointment/${appointmentId}/problem`,
      { problem: problem },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    const appointment = response.data.data.appointment
    const { statuses, counselor = {} } = appointment

    let status =
      statuses &&
      (statuses.length
        ? statuses[statuses.length - 1]
        : {
            status_key: "notfound",
            status_th: "ไม่ระบุสถานะ",
            status_en: "inconclusive",
          })
    status = this.helperService.convertToView(status)
    const appointment_date = appointment.date ? moment(appointment.date).format("DD/MM") : ""
    let appointment_name = this.languageService.language === "th" ? "การนัดหมาย" : "appointment"
    if (appointment.parent_appointment_id) {
      appointment_name =
        this.languageService.language === "th"
          ? `[นัดหมายต่อเนื่อง] ${appointment_name}`
          : `[follow-up] ${appointment_name}`
    }
    appointment_name = `${appointment_name} ${appointment_date}`

    return {
      ...appointment,
      id: appointment.id,
      status_key: status.status_key,
      status: status.status,
      name: appointment_name,
      appointment_date: appointment_date,
      appointment_date_text: this.getUserDateText(
        appointment.date,
        appointment.start_time,
        appointment.end_time
      ),
      type: "appointment",
      counselor: counselor
        ? { ...counselor, avatar_image: this.fileService.getFileUrl(counselor.avatar_image) }
        : {},
    }
  }
  public async cancelAppointment(appointmentId: number, form: any): Promise<void> {
    const response = await this.apiService.post<any>(
      `appointment/${appointmentId}/cancel`,
      { form },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    const appointment = response.data.data.appointment
    const { statuses, counselor = {} } = appointment

    let status =
      statuses &&
      (statuses.length
        ? statuses[statuses.length - 1]
        : {
            status_key: "notfound",
            status_th: "ไม่ระบุสถานะ",
            status_en: "inconclusive",
          })
    status = this.helperService.convertToView(status)
    const appointment_date = appointment.date ? moment(appointment.date).format("DD/MM") : ""
    let appointment_name = this.languageService.language === "th" ? "การนัดหมาย" : "appointment"
    if (appointment.parent_appointment_id) {
      appointment_name =
        this.languageService.language === "th"
          ? `[นัดหมายต่อเนื่อง] ${appointment_name}`
          : `[follow-up] ${appointment_name}`
    }
    appointment_name = `${appointment_name} ${appointment_date}`

    return {
      ...appointment,
      id: appointment.id,
      status_key: status.status_key,
      status: status.status,
      name: appointment_name,
      appointment_date: appointment_date,
      appointment_date_text: this.getUserDateText(
        appointment.date,
        appointment.start_time,
        appointment.end_time
      ),
      type: "appointment",
      counselor: counselor
        ? { ...counselor, avatar_image: this.fileService.getFileUrl(counselor.avatar_image) }
        : {},
    }
  }
  public async getRegisterWorkshop(userId?: string): Promise<Workshop[]> {
    const response = await this.apiService.get<any>(
      `portfolio/workshop${userId ? "?user=" + userId : ""}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data.data.workshop.list) {
      return []
    }
    return response.data.data.data.workshop.list
  }

  public async confirmWorkshop(workshopId: number): Promise<any> {
    const response = await this.apiService.post<any>(` workshops/${workshopId}/confirm`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    console.log(response)
  }

  public async cancelWorkshop(workshopId: number, note: string): Promise<void> {
    console.log("Cancle: " + workshopId)
    console.log("Reason: " + note)
    const response = await this.apiService.post<any>(
      `workshops/${workshopId}/cancel`,
      {
        reason: note,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    console.log(response)
  }

  public async getClientCancelAppointmentForm() {
    const response = await this.apiService.get<any>(`appointment/cancelform`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.form
  }

  public async getWorkshopDetail(workshopId: number, userId?: string): Promise<WorkshopDetail> {
    const response = await this.apiService.get<any>(
      `portfolio/workshop-status/${workshopId}${userId ? "?user=" + userId : ""}`
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data
  }

  public async getWorkshopAnnoucement(workshopId: number): Promise<IWorkshopAnnounce> {
    const response = await this.apiService.get<{ data: { data: IWorkshopAnnounce } }>(
      `workshops/${workshopId}/announcement`
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data.data
  }

  public async joinSessionRoom(appointmentId: string): Promise<void> {
    const response = await this.apiService.post<{}>(
      `appointment/${appointmentId}/queueing`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getSessionStatus(appointmentId: string): Promise<any> {
    const response = await this.apiService.get<any>(
      `appointment/${appointmentId}/queue`
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data.queue
  }
}
