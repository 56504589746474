export class CachableResource<T> {
  private msPerMinute = 60000
  private cachedResource?: T
  private lastUpdate?: Date

  private get isUpdateNeeded(): boolean {
    if (!this.lastUpdate) {
      return true
    }
    const now = new Date()
    if (now.valueOf() - this.lastUpdate.valueOf() > this.cacheTime * this.msPerMinute) {
      return true
    }
    return false
  }

  public get resource(): Promise<T> {
    return (async () => {
      if (this.isUpdateNeeded || !this.cachedResource) {
        this.cachedResource = await Promise.resolve(this.updateFunction())
        this.lastUpdate = new Date()
      }
      return this.cachedResource
    })()
  }

  public async forceUpdate(): Promise<void> {
    this.cachedResource = await Promise.resolve(this.updateFunction())
  }

  constructor(private updateFunction: () => T | Promise<T>, private cacheTime = 3) {}
}
