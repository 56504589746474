import { useObserver } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useTranslator } from "i18n/useTranslator"
import { useContainer } from "services/containerProvider"
import { AgreementModal } from "../AgreementModal"
import { UserAgreementModalViewModel } from "./UserAgreementModalViewModel"

export const UserAgreementModal = (): JSX.Element => {
  const t = useTranslator()
  const [viewModel] = useState(new UserAgreementModalViewModel(useContainer()))

  useEffect(() => {
    viewModel.init()
  }, [])

  return useObserver(() => (
    <>
      {viewModel.agreement && (
        <AgreementModal
          onAccept={viewModel.acceptAgreement}
          error={t(viewModel.error)}
          visible={viewModel.visible}
          agreement={viewModel.agreement}
          title={t("login.useragreement")}
        />
      )}
    </>
  ))
}
