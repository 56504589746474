import { inject, injectable } from "inversify"
import type { IApiService } from "./apiService"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import { TYPES } from "./types"

export interface ILineContactService {
  getUserInfo(line_user_id: any): Promise<any>
  createLineContact(lineContact: ILineContact): Promise<any>
}

export interface ILineContact {
  line_user_id: string
  phoneno: string
  location: string
}

@injectable()
export class LineContactService implements ILineContactService {
  private apiService: IApiService

  constructor(@inject(TYPES.IApiService) apiService: IApiService) {
    this.apiService = apiService
  }

  public async createLineContact(lineContact: ILineContact): Promise<any> {
    const resp1 = await this.apiService.post<any>("line/hotline/phone", lineContact, true)
    if (resp1.status !== ApiResponseStatus.Ok) {
      return null
    }
    const resp2 = await this.apiService.post<any>("line/hotline/location", lineContact, true)
    if (resp2.status !== ApiResponseStatus.Ok) {
      return null
    }
    return "phone : "+ resp1.data.data + "location : " + resp2.data.data
  }

  public async updateLocation(lineContact: ILineContact): Promise<any> {
    const resp = await this.apiService.post<any>("line/hotline/location", lineContact, true)
    if (resp.status !== ApiResponseStatus.Ok) {
      return null
    }
    return  "location : " + resp.data.data
  }

  public async getUserInfo(line_user_id: any): Promise<any> {
    const resp = await this.apiService.get<any>("line/hotline/user",line_user_id, true)
    if (resp.status !== ApiResponseStatus.Ok) {
      return null
    }
    return  resp.data.data
  }
}
