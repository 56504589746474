import React from "react"
import styles from "./ServiceCard.module.less"

interface IProps {
  name?: string
  image?: string
  description?: string
  onClick?: () => void
}

export const ServiceCard = (props: IProps): JSX.Element => {
  const { name, image, description, onClick = () => {} } = props
  return (
    <div className={styles.serviceCard} onClick={() => onClick()}>
      <div className={styles.serviceImg}>
        <img src={image} alt="" />
      </div>
      <div className={styles.serviceTitle}>{name}</div>
      <div className={styles.serviceDescription}>{description}</div>
    </div>
  )
}
