import { injectable, inject } from "inversify"
import { TYPES } from "./types"
import type { IConfigService } from "./configService"

export interface IFileService {
  getFileUrl(url?: string): string | null
}

@injectable()
export class FileService implements IFileService {
  private configService: IConfigService

  constructor(@inject(TYPES.IConfigService) configService: IConfigService) {
    this.configService = configService
  }

  public getFileUrl = (url?: string) => {
    if (!url) return null
    return `${this.configService.baseApiUrl}/${url}`
  }
}
