import { injectable, inject } from "inversify"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type { IConfigService } from "./configService"
import { ApiResponseStatus } from "../domains/interfaces/apiResponse"
import type { ILanguageService } from "./languageService"

export interface IBlacklistService {
  getBlacklistWorkshop(filter: any): Promise<any>
  getBlacklistAppointment(filter: any): Promise<any>
  addBlacklistWorkshop(id: number): Promise<void>
  addBlacklistAppointment(id: number): Promise<void>
  deleteBlacklist(id: number): Promise<void>
}

@injectable()
export class BlacklistService implements IBlacklistService {
  private apiService: IApiService
  private configService: IConfigService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ILanguageService) languageService: ILanguageService,
    @inject(TYPES.IConfigService) configService: IConfigService
  ) {
    this.apiService = apiService
    this.configService = configService
  }

  public async getBlacklistWorkshop(filter: any): Promise<any> {
    const response = await this.apiService.get<any>("blacklist/workshop", filter, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return []
    }
    // const { total, data } = response.data.data.articles
    // return {
    // }
  }

  public async getBlacklistAppointment(filter: any): Promise<any> {
    // const response = await this.apiService.get<any>("blacklist/workshop", filter, true)
    // if (response.status !== ApiResponseStatus.Ok) {
    //   return []
    // }
    // const { total, data } = response.data.data.articles
    // return {
    // }
  }

  public async addBlacklistWorkshop(id: number): Promise<void> {
    // const response = await this.apiService.post<any>(
    //   `backoffice/articles`,
    //   article,
    //   true
    // )
    // if (response.status !== ApiResponseStatus.Ok) {
    //   throw new Error(response.status + "")
    // }
  }

  public async addBlacklistAppointment(id: number): Promise<void> {
    // const response = await this.apiService.post<any>(
    //   `backoffice/articles`,
    //   article,
    //   true
    // )
    // if (response.status !== ApiResponseStatus.Ok) {
    //   throw new Error(response.status + "")
    // }
  }

  public async deleteBlacklist(id: number): Promise<void> {
    // const response = await this.apiService.delete<void>(`backoffice/articles/${id}`, {}, true)
    // if (response.status !== ApiResponseStatus.Ok) {
    //   throw new Error(response.status + "")
    // }
  }
}
