import { injectable, inject } from "inversify"
import moment from "moment"
import "moment/locale/th"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type { IConfigService } from "./configService"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type { ILanguageService } from "./languageService"
import type { IRegisterRequestBody } from "domains/interfaces/registration"
import type { IEditRequestBody } from "domains/interfaces/edit"

export interface IAccountManagementService {
  getUserAccount(filter: any): Promise<any>
  getByUsername(username: string): Promise<any>
  deleteUserAccount(userId: number): Promise<void>
  getStaffAccount(filter: any): Promise<any>
  deleteStaffAccount(filter: any): Promise<void>
  addStaffRole(userId: number, role: string): Promise<void>
  getAllRole(): Promise<any>
  deleteStaffRole(userId: number, role: string): Promise<any>
  createRole(rowName: string): Promise<any>
  editUserProfile(formResponse: any, userId: number): Promise<any>
  getUserProfile(userId: number): Promise<any>
  getUserAppointment(filter: any): Promise<any>
  resetStaffPassword(userId: number, newPassword: string): Promise<void>
  createCounselor(data: IRegisterRequestBody): Promise<any>
}

@injectable()
export class AccountManagementService implements IAccountManagementService {
  private apiService: IApiService
  private configService: IConfigService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ILanguageService) languageService: ILanguageService,
    @inject(TYPES.IConfigService) configService: IConfigService
  ) {
    this.apiService = apiService
    this.configService = configService
  }

  public getTableDateText(date: any) {
    if (!date) return ""
    else {
      return moment(date).format("DD/MM/YYYY HH:mm")
    }
  }

  public async createCounselor(data: IRegisterRequestBody): Promise<any> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      `backoffice/usermanagement/staff`,
      { ...data },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + ": " + response.data.message)
    }
  }

  public async getUserAccount(filter: any): Promise<any> {
    const response = await this.apiService.get<any>(
      "backoffice/usermanagement/customer",
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { total, data = [] } = response.data.data.customers
    return {
      total: total,
      users: data.map((_data: any) => {
        return {
          ..._data,
          created_at: this.getTableDateText(_data.created_at),
          lastSignin: this.getTableDateText(_data.lastSignin),
        }
      }),
    }
  }

  public async getByUsername(username: string): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/usermanagement/customer/user?username=${username}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    console.log(response)
    return response.data.data.user
  }

  public async deleteUserAccount(userId: number): Promise<void> {
    // const response = await this.apiService.delete<void>(`backoffice/articles/${id}`, {}, true)
    //   if (response.status !== ApiResponseStatus.Ok) {
    //     throw new Error(response.status + "")
    //   }
  }

  public async getStaffAccount(filter: any): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/usermanagement/staff", filter, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { total, data } = response.data.data.staffs
    return {
      total: total,
      staffs: data.map((_data: any) => {
        return {
          ..._data,
          registerDate: this.getTableDateText(_data.registerDate),
        }
      }),
    }
  }

  public async deleteStaffAccount(userId: number): Promise<void> {
    const response = await this.apiService.delete<void>(
      `backoffice/usermanagement/staff/${userId}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getAllRole(): Promise<any> {
    // const response = await this.apiService.get<any>("backoffice/user/", filter, true)
    // if (response.status !== ApiResponseStatus.Ok) {
    //   return null
    // }
    // const { total, data } = response.data.data.users
    // return {
    //   total: total,
    //   users: [],
    // }
  }

  public async addStaffRole(userId: number, role: string): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      `backoffice/usermanagement/staff/${userId}/addrole`,
      { role },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + ": " + response.data.message)
    }
  }

  public async deleteStaffRole(userId: number, role: string): Promise<void> {
    const response = await this.apiService.post<void>(
      `backoffice/usermanagement/staff/${userId}/deleterole`,
      { role },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async createRole(rowName: string): Promise<any> {}

  public async editUserProfile(editBody: IEditRequestBody, userId: number): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      `backoffice/usermanagement/staff/${userId}`,
      editBody
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async getUserProfile(userId: number): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/usermanagement/staff/${userId}`,
      {},
      true
    )
    console.log(response)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.user
  }

  public async getUserAppointment(id: any): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/usermanagement/customer/user/${id}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.appointments
  }

  public async resetStaffPassword(userId: number, newPassword: string): Promise<void> {
    const response = await this.apiService.post<void>(
      `backoffice/auth/${userId}/resetpassword`,
      // `backoffice/auth//resetpassword`,

      {
        newPassword: `${newPassword}`,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }
}
