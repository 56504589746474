import { injectable, inject } from "inversify"
import moment from "moment"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type {
  IArticle,
  IArticleFilter,
  IListArticleResponse,
  IArticleResponse,
  IResultArticleResponse,
} from "domains/interfaces/article"
import type { IConfigService } from "./configService"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type { ILanguageService } from "./languageService"
import type { IFileService } from "./fileService"

export interface IArticleService {
  getList(filter: IArticleFilter): Promise<any>
  getById(id: number): Promise<IArticle | null>
  BgetList(filter?: IArticleFilter): Promise<any>
  BgetById(id: number): Promise<IArticle | null>
  createArticle(article: IArticle | {}): Promise<IArticle>
  editArticle(id: number, article: IArticle): Promise<IArticle>
  deleteArticle(id: number): Promise<void>
}

@injectable()
export class ArticleService implements IArticleService {
  private apiService: IApiService
  private configService: IConfigService
  private fileService: IFileService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ILanguageService) languageService: ILanguageService,
    @inject(TYPES.IConfigService) configService: IConfigService,
    @inject(TYPES.IFileService) fileService: IFileService
  ) {
    this.apiService = apiService
    this.configService = configService
    this.fileService = fileService
  }

  public async getList(filter: IArticleFilter): Promise<any> {
    const response = await this.apiService.get<IListArticleResponse>("articles", filter, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data.articles) {
      return []
    }
    const { total, data } = response.data.data.articles
    return {
      total: total,
      articles: data.map((article: any) => {
        return {
          id: article.id,
          article_name: article.article_name_th,
          cover_image: this.fileService.getFileUrl(article.cover_image),
          tags: article.tags.map((tag: any) => tag.tag),
        }
      }),
    }
  }

  public async getById(id: number): Promise<IArticle | null> {
    const response = await this.apiService.get<IArticleResponse>(`articles/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { article } = response.data.data
    return {
      id: article.id,
      cover_image: this.fileService.getFileUrl(article.cover_image),
      article_name: article.article_name_th,
      published_at: article.published_at,
      tags: article.tags.map((tag: any) => tag.tag),
      content: article.content_markdown_th,
      author_name: article.author_name_th,
      author_position: article.author_position_th,
      attachment: this.fileService.getFileUrl(article.attachment),
    }
  }

  //backoffice
  public async BgetList(filter?: IArticleFilter): Promise<any> {
    const response = await this.apiService.get<IListArticleResponse>(
      "backoffice/articles",
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data.articles) {
      return []
    }

    const { total, data } = response.data.data.articles
    return {
      total: total,
      articles: data.map((article: any) => {
        return {
          id: article.id,
          article_name: article.article_name_th,
          cover_image: this.fileService.getFileUrl(article.cover_image),
          view_count: article.view_count,
          published: article.published,
          created_at: moment(article.created_at).format("DD/MM/YYYY HH:mm"),
        }
      }),
    }
  }

  public async BgetById(id: number): Promise<any> {
    const response = await this.apiService.get<any>(`articles/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { article } = response.data.data
    return {
      id: article.id,
      cover_image: this.fileService.getFileUrl(article.cover_image),
      article_name: article.article_name_th,
      content: article.content_markdown_th,
      attachment: this.fileService.getFileUrl(article.attachment),
      author_name: article.author_name_th,
      author_position: article.author_position_th,
      tags: article.tags.map((tag: any) => tag.tag),
      published: article.published,
      published_at: article.published_at
        ? moment(article.published_at, "YYYY-MM-DD HH:mm:ss")
        : undefined,
    }
  }

  public async createArticle(article: IArticle | {}): Promise<IArticle> {
    const response = await this.apiService.post<IResultArticleResponse>(
      `backoffice/articles`,
      article,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    const { result } = response.data.data
    return { id: result.id }
  }

  public async editArticle(id: number, article: any): Promise<any> {
    const response = await this.apiService.postFormData<any>(
      `backoffice/articles/${id}`,
      article,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.article
  }

  public async deleteArticle(id: number): Promise<void> {
    const response = await this.apiService.delete<void>(`backoffice/articles/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }
}
