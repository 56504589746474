import type { Container } from "inversify"
import { TYPES } from "services/types"
import { observable, action, computed } from "mobx"
import type { IRegisterRequestBody } from "domains/interfaces/registration"
import type { IRegistrationService } from "services/registrationService"
import type { Navigation } from "navi"

export class RegisterViewModel {
  private registrationService: IRegistrationService
  private navigation: Navigation<any>

  @observable
  public errorMessage = ""

  @observable
  public redirectPath: string

  @observable
  public mappingId: string

  @observable
  public mappingToken: string

  constructor(container: Container, navigation: Navigation<any>) {
    this.registrationService = container.get<IRegistrationService>(TYPES.IRegistrationService)
    this.navigation = navigation

    const urlParams = new URLSearchParams(window.location.search)
    this.redirectPath = decodeURIComponent(urlParams.get("redirect") ?? "/")
    this.mappingId = decodeURIComponent(urlParams.get("mapping_id") ?? "")
    this.mappingToken = decodeURIComponent(urlParams.get("mapping_token") ?? "")
  }

  @computed
  get loginQueryParams(): string {
    const urlParams = new URLSearchParams()
    if (this.redirectPath.length > 0) {
      urlParams.append('redirect', this.redirectPath)
    }
    if (this.mappingId.length > 0 && this.mappingToken.length > 0) {
      urlParams.append('mapping_id', this.mappingId)
      urlParams.append('mapping_token', this.mappingToken)
    }
    return urlParams.size > 0 ? "?" + urlParams.toString() : ""
  }

  @action.bound
  public async onSubmitForm(values: any) {
    const { email, username, password, firstname, lastname, sex } = values
    const registrationBody: IRegisterRequestBody = {
      firstname,
      lastname,
      username,
      email,
      password,
      sex,
    }
    try {
      await this.registrationService.register(registrationBody)
      this.navigation.navigate(`login${this.loginQueryParams}`)
    } catch (error) {
      this.errorMessage = (error as Error).message
    }
  }

  @action.bound
  public async isUsernameAvailable(username: string): Promise<boolean> {
    return this.registrationService.isUsernameAvailable(username)
  }

  @action.bound
  public validateUsernameAlphaNum(username: string): boolean {
    if (username.match(/^[0-9a-zA-Z_]+$/)) {
      return true
    }
    return false
  }
}
