import { useObserver } from "mobx-react"
import React, { useState, useRef, useEffect } from "react"
import { Col } from "components/base/Col/Col"
import { Row } from "components/base/Row/Row"
import Phone from "assets/images/footer/phone.svg"
import Facebook from "assets/images/footer/facebook.svg"
// import {agreement} from ""
import { IStaticService } from "services/staticService"

import styles from "./FooterAgreement.module.less"
import { Markdown } from "components/base/Markdown/Markdown"
import { useTranslator } from "i18n/useTranslator"
import { FooterAgreementViewModel } from "./FooterAgreementViewModel"
import { useContainer } from "services/containerProvider"
import { AgreementModal } from "../AgreementModal/AgreementModal"
import { Button } from "components/base/Button/Button"
import { Modal } from "components/base/Modal/Modal"
import { Alert } from "components/base/Alert/Alert"
import { Checkbox } from "components/base/Checkbox/Checkbox"
import { Space } from "components/base/Space/Space"
import { Switch } from "components/base/Switch/Switch"

export const FooterAgreement = (): JSX.Element => {
  const t = useTranslator()
  const cardElement = useRef<HTMLDivElement>(null)
  const [viewModel] = useState(new FooterAgreementViewModel(useContainer()))

  useEffect(() => {
    viewModel.cardElement = cardElement
  }, [])

  useEffect(() => {
    viewModel.init()
  }, [])

  return useObserver(() => (
    <Row className={styles.row} align="middle" justify="center">
      <Modal
        // width={450}
        visible={viewModel.isModalCookieSettingVisible}
        footer={null}
        onCancel={() => viewModel.setCookieSettingModalVisible(false)}>
        <div className={styles.confirmModalWrapper}>
          <div className={styles.modalHeader + " " + styles.cookieTitle}>
            {"การตั้งค่าความเป็นส่วนตัว"}
          </div>

          <div className={styles.cookieSection} >
            <div className={styles.cookieInline}>
              <div > {"คุกกี้พื้นฐานที่จำเป็น"} </div>
              <Switch disabled defaultChecked></Switch>
            </div>
            <div className={styles.cookieDetail}>
              {"คุกกี้พื้นฐานที่จำเป็น เพื่อช่วยให้การทำงานหลักของเว็บไซต์ใช้งานได้ รวมถึงการเข้าถึงพื้นที่ที่ปลอดภัยต่าง ๆ ของเว็บไซต์ หากไม่มีคุกกี้นี้เว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม และจะใช้งานได้โดยการตั้งค่าเริ่มต้น โดยไม่สามารถปิดการใช้งานได้"}
            </div>
          </div>
          <div />
          <div className={styles.cookieSection} >
            <div className={styles.cookieInline}>
              <div > {"คุกกี้ในส่วนวิเคราะห์"} </div>
              <Switch checked={viewModel.cookieValues.analyze} onChange={(b) => viewModel.onCookieSwitch(b, "analyze")} defaultChecked></Switch>
            </div>
            <div className={styles.cookieDetail}>
              {"คุกกี้ในส่วนวิเคราะห์ จะช่วยให้เว็บไซต์เข้าใจรูปแบบการใช้งานของผู้เข้าชมและจะช่วยปรับปรุงประสบการณ์การใช้งาน โดยการเก็บรวบรวมข้อมูลและรายงานผลการใช้งานของผู้ใช้งาน"}
            </div>
          </div>
          {/* <div className={styles.cookieSection} >
            <div className={styles.cookieInline}>
              <div > {"คุกกี้ในส่วนการตลาด"} </div>
              <Switch checked={viewModel.cookieValues.marketing} onChange={(b) => viewModel.onCookieSwitch(b, "marketing")} defaultChecked></Switch>
            </div>
            <div className={styles.cookieDetail}>
              {"คุกกี้ในส่วนการตลาด ใช้เพื่อติดตามพฤติกรรมผู้เข้าชมเว็บไซต์เพื่อแสดงโฆษณาที่เหมาะสมสำหรับผู้ใช้งานแต่ละรายและเพื่อเพิ่มประสิทธิผลการโฆษณาสำหรับบุคคลที่สาม"}
            </div>
          </div> */}

          <div style={{ textAlign: "right" }}> <span style={{ fontWeight: "lighter" }}> ศึกษาเพิ่มเติม</span>
            <a
              className={styles.footerLink}
              target="_blank"
              href={viewModel.getCookiePolicyPDF()}
              rel="noreferrer">
              รายละเอียดคุกกี้
            </a>
          </div>
          <div className={styles.modalFooter}>
            <Button className={styles.modalBtn} style={{ width: "167px" }} onClick={() => viewModel.onConfirmCookie(false)}>
              {"ยืนยันตัวเลือกของฉัน"}
            </Button>
            <Button type="primary" className={styles.modalBtn} onClick={() => viewModel.onConfirmCookie(true)}>
              {"ยอมรับทั้งหมด"}
            </Button>
          </div>
        </div>
      </Modal>
      {
        viewModel.agreement && viewModel.visible &&
        <div className={styles.cookieFooter} >
          <div className={styles.cookieContent}>
            <div>
              เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้เว็บไซต์ คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้โดยคลิก "การตั้งค่าคุกกี้" หรือศึกษา<a
                className={styles.footerLink}
                target="_blank"
                href={viewModel.getAgreementPDF()}
                rel="noreferrer">
                นโยบายความเป็นส่วนตัว
              </a>
            </div>
            <div style={{ textAlign: "right", paddingTop: "10px" }}>
              <button onClick={() => viewModel.openCookieSetting()} className={styles.cookieSetting}>การตั้งค่าคุกกี้</button>
              <Button
                key="continue"
                // disabled={!viewModel.acceptAvailable}
                style={{ margin: "auto" }}
                onClick={() => viewModel.onConfirmCookie(true)}>
                ยอมรับ
              </Button>
            </div>

          </div>
        </div>

      }
    </Row>
  ))
}
