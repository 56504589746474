import { useObserver } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Link, useNavigation } from "react-navi"
import { Button } from "components/base/Button/Button"
import { Divider } from "components/base/Divider/Divider"
import { MainLayoutWithCard } from "components/layout/MainLayout/WithCard/MainLayoutWithCard"
import { NotFoundViewModel } from "./NotFoundViewModel"
import container from "inversify.config"

import styles from "./NotFound.module.less"

export const NotFound = (): JSX.Element => {
  const viewModel = new NotFoundViewModel(container)
  viewModel.init()

  return (
    <MainLayoutWithCard>
      {viewModel.isAuth() && (
        <>
          <span className={styles.header}>404 NOT FOUND</span>
          <Divider />
          <h1>เหมือนจะมีบางอย่างผิดปกตินะ !</h1>
          <p>หน้าที่คุณร้องขอไม่มีอยู่</p>
          <Divider />
          <h1>You have some problems !</h1>
          <p>The page you're looking for doesn't exist.</p>
          <Divider />
          <Link href="/">
            <Button type="primary">กลับไปยังหน้าแรก Go home</Button>
          </Link>
        </>
      )}
      {!viewModel.isAuth() && (
        <>
          <h1>กรุณารอสักครู่...</h1>
          <Divider />
          <h1>Please wait a moment...</h1>
        </>
      )}
    </MainLayoutWithCard>
  )
}
