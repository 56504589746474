import React, { useState } from "react"
import { useTranslator } from "i18n/useTranslator"
import { useContainer } from "services/containerProvider"

import styles from "./Logo.module.less"
import { LogoViewModel } from "./LogoViewModel"

export const Logo = (): JSX.Element => {
  const t = useTranslator()
  const [viewModel] = useState(new LogoViewModel(useContainer()))

  return (
    <div className={styles.logo}>
      {t("applicationName")}
      {viewModel.branch.length > 0 && (
        <div className={styles.decorate} style={{ backgroundColor: viewModel.color }}>
          {viewModel.branch}
        </div>
      )}
    </div>
  )
}
