import React from "react"
import { Link } from "react-navi"
import container from "inversify.config"
import type { IAuthService } from "services/authService"
import type { ILanguageService } from "services/languageService"
import { TYPES } from "services/types"
import type { IUserService } from "services/userService"
import { Menu } from "components/base/Menu/Menu"
import { MenuItem } from "components/base/Menu/MenuItem/MenuItem"

const authService = container.get<IAuthService>(TYPES.IAuthService)
const userService = container.get<IUserService>(TYPES.IUserService)
const languageService = container.get<ILanguageService>(TYPES.ILanguageService)

const onUserClick = async ({ key }: any) => {
  if (key === "logout") {
    await authService.customerLogout()
    window.location.reload()
  } else if (key === "profile") {
    window.location.href = "/profile/detail"
  }
}

const t = (key: string): string => {
  if (key === "navbar.logout") {
    if (languageService.language === "en") {
      return "Logout"
    } else {
      return "ออกจากระบบ"
    }
  }
  return key
}

export const NavbarMenuUserMenu = (): JSX.Element => {
  return (
    <Menu onClick={onUserClick}>
      {userService.userObservable && (
        <MenuItem key="profile">
          {`${userService.userObservable.firstname} ${userService.userObservable.lastname}`}
        </MenuItem>
      )}
      <MenuItem key="logout">{t("navbar.logout")}</MenuItem>
    </Menu>
  )
}
