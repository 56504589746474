import { changeAntdTheme } from "mini-dynamic-antd-theme"
import { injectable } from "inversify"
import type { IUserService } from "./userService"
import { TYPES } from "./types"
import { inject } from "inversify"
import type { CounselorType } from "domains/interfaces/profile"

const PRIMARY_PSYCHOLOGIST = "#66bb9e"
const PRIMARY_PSYCHIATRIST = "#ff782d"

export interface IThemeService {
  rollbackTheme(): void
  autoSetTheme(role?: CounselorType): Promise<void>
}

@injectable()
export class ThemeService implements IThemeService {
  private userService: IUserService

  constructor(@inject(TYPES.IUserService) userService: IUserService) {
    this.userService = userService
  }

  public async rollbackTheme() {
    changeAntdTheme(PRIMARY_PSYCHOLOGIST)
  }

  public async autoSetTheme(role: CounselorType = "psychologist") {
    if (role === "psychiatrist" || (await this.userService.isPsychiatrist)) {
      changeAntdTheme(PRIMARY_PSYCHIATRIST)
    } else changeAntdTheme(PRIMARY_PSYCHOLOGIST)
  }
}
