import type { Container } from "inversify"
import type { ILanguageService, LanguageString } from "services/languageService"
import { TYPES } from "services/types"
import { IReactionDisposer, reaction } from "mobx"
import type { i18n } from "i18next"

export class I18nProviderViewModel {
  private languageService: ILanguageService
  private disposer: IReactionDisposer
  private i18nInstance: i18n

  constructor(container: Container, i18nInstance: i18n) {
    this.languageService = container.get<ILanguageService>(TYPES.ILanguageService)
    this.i18nInstance = i18nInstance

    this.changeLanguage.bind(this)
    this.disposer = reaction(
      () => this.languageService.language,
      (language, _) => {
        this.changeLanguage(language)
      }
    )
    this.languageService.setLanguage(this.i18nInstance.language as LanguageString)
  }

  changeLanguage(language: LanguageString) {
    this.i18nInstance.changeLanguage(language)
  }

  dispose() {
    this.disposer()
  }
}
