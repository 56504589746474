import type { Container } from "inversify"
import { TYPES } from "services/types"
import type { IAuthService } from "services/authService"

export class FooterContentViewModel {
  private authService: IAuthService

  constructor(container: Container) {
    this.authService = container.get<IAuthService>(TYPES.IAuthService)
  }

  public getAgreementPDF() {
    return this.authService.getPrivacyPolicyURL()
  }
}
