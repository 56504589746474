import { useTranslation } from "react-i18next"
import type { TOptions, StringMap } from "i18next"

export const useTranslator = () => {
  const { t } = useTranslation()
  const translator = (key: string, options?: string | TOptions<StringMap> | undefined) => {
    return t(key, options)
  }
  return translator
}
