import { inject, injectable } from "inversify"
import type { IApiService } from "./apiService"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import { TYPES } from "./types"
import type { IMedicine } from "domains/interfaces/backoffice/medication"
import type { IMedicineForm } from "pages/backoffice/MedicineManagement/Form/MedicineFormViewModel"

export interface IMedicineService {
  getMedicines(): Promise<IMedicine[]>
  createMedicine(medicine: IMedicineForm): Promise<any>
  deleteMedicine(medicineId: number): Promise<any>
}

interface IMedicineResponse {
  data: {
    medicine: IMedicine[]
  }
}

@injectable()
export class MedicineService implements IMedicineService {
  private apiService: IApiService

  constructor(@inject(TYPES.IApiService) apiService: IApiService) {
    this.apiService = apiService
  }

  public async getMedicines(): Promise<IMedicine[]> {
    const resp = await this.apiService.get<IMedicineResponse>("backoffice/medicine", {}, true)
    if (resp.status !== ApiResponseStatus.Ok || resp.data.data.medicine.length === 0) {
      return []
    }
    return resp.data.data.medicine
  }

  public async createMedicine(medicine: IMedicineForm): Promise<any> {
    const resp = await this.apiService.post<IMedicineResponse>(
      "backoffice/medicine",
      medicine,
      true
    )
    if (resp.status !== ApiResponseStatus.Ok) {
      return null
    }
    return resp.data.data
  }

  public async deleteMedicine(medicineId: number): Promise<any> {
    const resp = await this.apiService.delete<any>(`backoffice/medicine/${medicineId}`, {}, true)
    if (resp.status !== ApiResponseStatus.Ok) {
      return null
    }
    return resp.data.data
  }
}
