import { injectable } from "inversify"
import type { IForm, IFormQuestion, IFormResponseAnswer } from "domains/interfaces/form"

export interface IFormService {
  mapFormAnswerToAnotherFormAnswer(
    sourceForm: IForm,
    targetForm: IForm,
    questionMapper: any,
    choiceMapperBackward: any,
    sourceFormAnswer: any
  ): any
  mapFormAnswerWithSameQuestion(sourceForm: IForm, targetForm: IForm, sourceFormAnswer: any): any
  getChoiceDescription(questions: any, choiceId: number): any
  convertFormUIAnswerToFormAnswer(
    answer: any,
    questions: IFormQuestion[],
    otherMapper?: Map<number, string>
  ): IFormResponseAnswer[]
  convertFormAnswerToFormUIAnswer(
    answers: IFormResponseAnswer[],
    questions: IFormQuestion[]
  ): { answerUI: any; otherMapper: Map<number, string> }
}

@injectable()
export class FormService implements IFormService {
  public mapFormAnswerToAnotherFormAnswer(
    sourceForm: IForm,
    targetForm: IForm,
    questionMapper: any,
    choiceMapperBackward: any,
    sourceFormAnswer: any
  ): any {
    const targetAnswer: any = {}
    for (const question of targetForm.questions) {
      const targetQuestionEn = questionMapper[question.question_en]
      const value = sourceFormAnswer[questionMapper[question.question_en]]
      if (value) {
        if (typeof value === "number") {
          const profileQuestion = sourceForm.questions.find(
            (profileQuestion: any) => profileQuestion.question_en === targetQuestionEn
          )
          if (profileQuestion) {
            const profileChoice = profileQuestion.choices.find((choice: any) => choice.id === value)
            if (profileChoice) {
              const targetChoice = question.choices.find(
                choice =>
                  choice.description_en ===
                  choiceMapperBackward[targetQuestionEn][profileChoice.description_en]
              )
              if (targetChoice) {
                targetAnswer[question.question_en] = targetChoice.id
              }
            }
          }
        } else {
          targetAnswer[question.question_en] = value
        }
      }
    }
    return targetAnswer
  }

  public mapFormAnswerWithSameQuestion(
    sourceForm: IForm,
    targetForm: IForm,
    sourceFormAnswer: any
  ): any {
    const targetAnswer: any = {}
    for (const question of targetForm.questions) {
      const targetQuestionEn = question.question_en
      const value = sourceFormAnswer[question.question_en]
      if (value) {
        if (typeof value === "number") {
          const profileQuestion = sourceForm.questions.find(
            (profileQuestion: any) => profileQuestion.question_en === targetQuestionEn
          )
          if (profileQuestion) {
            const profileChoice = profileQuestion.choices.find((choice: any) => choice.id === value)
            if (profileChoice) {
              const targetChoice = question.choices.find(
                choice => choice.description_en === profileChoice.description_en
              )
              if (targetChoice) {
                targetAnswer[question.question_en] = targetChoice.id
              }
            }
          }
        } else {
          targetAnswer[question.question_en] = value
        }
      }
    }
    return targetAnswer
  }

  public getChoiceDescription(questions: any, choiceId: number) {
    let choiceEn
    questions.forEach((question: any) => {
      if (
        question.question_type === "selection" ||
        question.question_type === "radio" ||
        question.question_type === "choice"
      ) {
        question.choices.forEach((choice: any) => {
          if (choice.id === choiceId) {
            choiceEn = choice.description_en
          }
        })
      }
    })

    return choiceEn
  }

  public convertFormUIAnswerToFormAnswer(
    answer: any,
    questions: IFormQuestion[],
    otherMapper?: Map<number, string> // Map from question id to other text (for choiceother question)
  ): IFormResponseAnswer[] {
    const questionMapper = new Map<string, number>()
    for (const question of questions) {
      questionMapper.set(question.question_en, question.id)
    }
    const formAnswer: IFormResponseAnswer[] = []
    for (const questionEn of Object.keys(answer)) {
      const value = answer[questionEn]
      const questionId = questionMapper.get(questionEn) ?? -1
      if (typeof value === "number") {
        if (value === -1 && otherMapper !== undefined) {
          formAnswer.push({
            id: questionId,
            choiceId: -1,
            text: otherMapper.get(questionId),
          })
        } else {
          formAnswer.push({
            id: questionId,
            choiceId: value,
          })
        }
      } else {
        formAnswer.push({
          id: questionId,
          text: value as string,
        })
      }
    }
    return formAnswer
  }

  public convertFormAnswerToFormUIAnswer(
    answers: IFormResponseAnswer[],
    questions: IFormQuestion[]
  ): { answerUI: any; otherMapper: Map<number, string> } {
    const idToQuestionMapper = new Map<number, IFormQuestion>()
    const otherMapper = new Map<number, string>()
    for (const question of questions) {
      idToQuestionMapper.set(question.id, question)
    }
    const answerUI: any = {}
    for (const answer of answers) {
      if (answer.choiceId) {
        if (answer.choiceId === -1) {
          otherMapper.set(answer.id, answer.text ?? "")
        }
        answerUI[idToQuestionMapper.get(answer.id)?.question_en ?? ""] = answer.choiceId
      } else {
        answerUI[idToQuestionMapper.get(answer.id)?.question_en ?? ""] = answer.text
      }
    }
    return {
      answerUI,
      otherMapper,
    }
  }
}
