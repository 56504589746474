export enum ApiResponseStatus {
  Ok = 200,
  Created = 201,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  BadGateway = 502,
  Other,
}

const apiResponseStatusMap = new Map<number, ApiResponseStatus>()
apiResponseStatusMap.set(200, ApiResponseStatus.Ok)
apiResponseStatusMap.set(201, ApiResponseStatus.Created)
apiResponseStatusMap.set(400, ApiResponseStatus.BadRequest)
apiResponseStatusMap.set(401, ApiResponseStatus.Unauthorized)
apiResponseStatusMap.set(403, ApiResponseStatus.Forbidden)
apiResponseStatusMap.set(404, ApiResponseStatus.NotFound)
apiResponseStatusMap.set(500, ApiResponseStatus.InternalServerError)
apiResponseStatusMap.set(502, ApiResponseStatus.BadGateway)

export const getApiResponseStatus = (status: number) => {
  return apiResponseStatusMap.get(status) || ApiResponseStatus.Other
}

export interface IApiResponse<T> {
  status: ApiResponseStatus
  data: T
}

export interface IBaseResponse<T> {
  message?: string
  errors?: unknown
  data?: T
}
