import { injectable } from "inversify"

type callback = (...args: any[]) => void

export interface IEventEmitterService {
  events: Map<string, callback[]>
  on(event: string, listener: callback): callback
  removeListener(event: string, listener: callback): void
  removeAllListener(event: string): void
  emit(event: string, ...args: any[]): void
  once(event: string, listener: callback): void
}

@injectable()
export class EventEmitterService implements IEventEmitterService {
  events: Map<string, callback[]>

  constructor() {
    this.events = new Map<string, callback[]>()
  }

  on(event: string, listener: callback): callback {
    if (!this.events.has(event)) {
      this.events.set(event, [])
    }
    this.events.get(event)?.push(listener)
    return () => this.removeListener(event, listener)
  }

  removeListener(event: string, listener: callback): void {
    const idx = this.events.get(event)?.indexOf(listener) ?? -1
    if (idx > -1) {
      this.events.get(event)?.splice(idx, 1)
    }
  }

  removeAllListener(event: string): void {
    this.events.forEach(listeners => {
      listeners.splice(0, listeners.length)
    })
  }

  emit(event: string, ...args: any[]): void {
    this.events.get(event)?.forEach(listener => listener.apply(this, args))
  }

  once(event: string, listener: callback): void {
    const remove: () => void = this.on(event, (...args: any[]) => {
      remove()
      listener.apply(this, args)
    })
  }
}
