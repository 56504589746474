import { Container } from "inversify"
import { TYPES } from "./services/types"
import { IApiService, ApiService } from "./services/apiService"
import { IConfigService, ConfigService } from "./services/configService"
import { ILanguageService, LanguageService } from "./services/languageService"
import { IEventEmitterService, EventEmitterService } from "./services/eventEmitterService"
import { ITokenService, TokenService } from "./services/tokenService"
import { IAuthService, AuthService } from "./services/authService"
import { IAssessmentService, AssessmentService } from "./services/assessmentService"
import { IStaticService, StaticService } from "./services/staticService"
import { IHelperService, HelperService } from "./services/helperService"
import { IRegistrationService, RegistrationService } from "./services/registrationService"
import { IHotlineService, HotlineService } from "./services/hotlineService"
import { IWorkshopService, WorkshopService } from "./services/workshopService"
import { IArticleService, ArticleService } from "./services/articleService"
import { IProfileService, ProfileService } from "./services/profileService"
import { IAppointmentService, AppointmentService } from "./services/appointmentService"
import { IDateService, DateService } from "./services/dateService"
import {
  IBackofficeAppointmentService,
  BackofficeAppointmentService,
} from "./services/backofficeAppointmentService"
import { IUserService, UserService } from "./services/userService"
import {
  IAccountManagementService,
  AccountManagementService,
} from "./services/accountManagementService"
import { IFileService, FileService } from "./services/fileService"
import { frontPageService, IFrontPageService } from "./services/frontPageService"
import { FormService, IFormService } from "./services/formService"
import {
  BackofficeHotlineService,
  IBackofficeHotlineService,
} from "./services/backofficeHotlineService"
import { BlacklistService, IBlacklistService } from "./services/blacklistService"
import { BackofficeZoomService, IBackofficeZoomService } from "./services/backofficeZoomService"
import { IThemeService, ThemeService } from "services/themeService"
import { IPrescriptionService, PrescriptionService } from "services/prescriptionService"
import { IMedicineService, MedicineService } from "services/medicineService"
import { ILineContactService, LineContactService } from "services/lineContactService"

const container = new Container({
  defaultScope: "Singleton",
})
container.bind<IApiService>(TYPES.IApiService).to(ApiService)
container.bind<IStaticService>(TYPES.IStaticService).to(StaticService)
container.bind<IConfigService>(TYPES.IConfigService).to(ConfigService)
container.bind<IHelperService>(TYPES.IHelperService).to(HelperService)
container.bind<ILanguageService>(TYPES.ILanguageService).to(LanguageService)
container.bind<IEventEmitterService>(TYPES.IEventEmitterService).to(EventEmitterService)
container.bind<ITokenService>(TYPES.ITokenService).to(TokenService)
container.bind<IAuthService>(TYPES.IAuthService).to(AuthService)
container.bind<IHotlineService>(TYPES.IHotlineService).to(HotlineService)
container.bind<IAssessmentService>(TYPES.IAssessmentService).to(AssessmentService)
container.bind<IRegistrationService>(TYPES.IRegistrationService).to(RegistrationService)
container.bind<IWorkshopService>(TYPES.IWorkshopService).to(WorkshopService)
container.bind<IArticleService>(TYPES.IArticleService).to(ArticleService)
container.bind<IProfileService>(TYPES.IProfileService).to(ProfileService)
container.bind<IAppointmentService>(TYPES.IAppointmentService).to(AppointmentService)
container.bind<IDateService>(TYPES.IDateService).to(DateService)
container.bind<IUserService>(TYPES.IUserService).to(UserService)
container
  .bind<IAccountManagementService>(TYPES.IAccountManagementService)
  .to(AccountManagementService)
container
  .bind<IBackofficeAppointmentService>(TYPES.IBackofficeAppointmentService)
  .to(BackofficeAppointmentService)
container.bind<IFileService>(TYPES.IFileService).to(FileService)
container.bind<IFrontPageService>(TYPES.IFrontPageService).to(frontPageService)
container.bind<IFormService>(TYPES.IFormService).to(FormService)
container
  .bind<IBackofficeHotlineService>(TYPES.IBackofficeHotlineService)
  .to(BackofficeHotlineService)
container.bind<IBlacklistService>(TYPES.IBlacklistService).to(BlacklistService)
container.bind<IPrescriptionService>(TYPES.IPrescriptionService).to(PrescriptionService)
container.bind<IBackofficeZoomService>(TYPES.IBackofficeZoomService).to(BackofficeZoomService)
container.bind<IThemeService>(TYPES.IThemeService).to(ThemeService)
container.bind<IMedicineService>(TYPES.IMedicineService).to(MedicineService)
container.bind<ILineContactService>(TYPES.ILineContactService).to(LineContactService)
export default container
